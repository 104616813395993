import { Responsive, WidthProvider } from 'react-grid-layout';
import { DataGrid } from './types';

const MINIMUM_GRID_COLUMN_WIDTH = 3;
const MINIMUM_GRID_ROW_HEIGHT = 6;

// @ts-ignore
export const responsiveUtils = Responsive.utils;
export const ResponsiveGridLayout = WidthProvider(Responsive);

export const layoutMarginXY: [number, number] = [5, 5];
export const layoutBreakpoints = { lg: 1900, md: 1000, sm: 600, xs: 0 };
export const layoutColumns = { lg: 48, md: 24, sm: 12, xs: MINIMUM_GRID_COLUMN_WIDTH };

export const layoutRowSize = 30;

export const defaultDataGrid: Partial<DataGrid> = {
  minW: MINIMUM_GRID_COLUMN_WIDTH,
  maxW: undefined,
  minH: MINIMUM_GRID_ROW_HEIGHT,
  maxH: undefined,
};

export const getCurrentBreakpoint = (isMobile: boolean): string => {
  if (isMobile) {
    return 'xs';
  }

  const sidebar = document.getElementById('flux-sidebar-permanent')?.offsetWidth || 0;
  const containerWidth = window.innerWidth - sidebar;

  return responsiveUtils.getBreakpointFromWidth(layoutBreakpoints, containerWidth);
};

export const getDataGrid = (breakpoint: string): Omit<DataGrid, 'i'> => {
  if (breakpoint === 'xs') {
    return {
      ...defaultDataGrid,
      w: MINIMUM_GRID_COLUMN_WIDTH,
      h: layoutRowSize / 3,
      x: 0,
      y: Infinity,
    };
  }

  const cols = responsiveUtils.getColsFromBreakpoint(breakpoint, layoutColumns);

  return {
    ...defaultDataGrid,
    w: cols === MINIMUM_GRID_COLUMN_WIDTH ? cols : cols / 2,
    h: layoutRowSize / 2,
    x: 0,
    y: 0,
  };
};
