import { GetRowIdParams, RowClassParams } from 'ag-grid-community';
import { Grid } from '../grid';
import { TradingPopOver } from './TradingPopOver';
import { useTradingGrid } from './hooks/useTradingGrid';
import { TradingRow, TradingWidgetPayload } from './tradingTypes';

const getRowId = ({ data: { tenorId } }: GetRowIdParams<TradingRow>): string => tenorId;

const isJuneDecRow = (params: RowClassParams<TradingRow, any>) =>
  params.data?.tenorFrequency === 'monthly' && (params.data.tenorName.includes('JUN') || params.data.tenorName.includes('DEC'));

const getRowStyle = (params: RowClassParams<TradingRow, any>) => {
  if (isJuneDecRow(params)) {
    return { borderBottom: '1px solid grey', borderTop: '1px solid grey' };
  }
  return undefined;
};

interface TradingGridProps {
  payload: TradingWidgetPayload;
  selectedTradingAccount: string | undefined;
  selectedExchange: string;
}

export const TradingGrid = ({ payload, selectedTradingAccount, selectedExchange }: TradingGridProps) => {
  const {
    gridRef,
    onColumnMoved,
    columnDefs,
    columns,
    rows,
    onCellClicked,
    selectedTradingProduct = { rowData: undefined, symbol: '' },
    isBidPopover,
    anchorEl,
    onCloseClickedCell,
  } = useTradingGrid(payload);

  const onCloseTradingPopover = () => {
    onCloseClickedCell();
  };

  return (
    <>
      <Grid<TradingRow>
        ref={gridRef}
        defaultColDef={columnDefs}
        columnDefs={columns}
        rowData={rows}
        getRowId={getRowId}
        onColumnMoved={onColumnMoved}
        onCellClicked={onCellClicked}
        suppressRowVirtualisation
        suppressColumnVirtualisation
        getRowStyle={getRowStyle}
        headerHeight={0}
        groupHeaderHeight={20}
        tooltipShowDelay={0}
        suppressChangeDetection
        rowHeight={25}
      />
      {selectedTradingProduct && anchorEl && (
        <TradingPopOver
          isBidPopover={isBidPopover}
          selectedTradingAccount={selectedTradingAccount}
          selectedExchange={selectedExchange}
          selectedTradingProduct={selectedTradingProduct}
          anchorEl={anchorEl}
          onClose={onCloseTradingPopover}
        />
      )}
    </>
  );
};
