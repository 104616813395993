import { DataApi } from '@shared/utils/data';
import { useCallback, useMemo, useState } from 'react';

export const useDataLoader = <T>(loader: DataApi<T>) => {
  const [data, setNewData] = useState<T[]>([]);
  const [dataLoader, setDataLoader] = useState<DataApi<T>>(loader);

  const fetchData = useCallback(
    async (newFilters?: Record<string, any>, searchText?: string): Promise<void> => {
      if (newFilters) dataLoader.resetQuery(newFilters);
      if (searchText) dataLoader.addSearchFilter(searchText);
      else if (searchText === '') dataLoader.addFilter({ search: undefined });

      if (!dataLoader.isDataLoading()) {
        await dataLoader.loadData();
        setNewData(dataLoader.data);
        setDataLoader(dataLoader);
      }
    },
    [setNewData, setDataLoader]
  );

  const fetchMoreData = useCallback(async (): Promise<void> => {
    if (dataLoader && !dataLoader.isDataLoading() && dataLoader.hasMoreData()) {
      fetchData();
    }
  }, [dataLoader]);

  const resetFilters = useCallback(
    (newFilters: Record<string, any>) => {
      if (dataLoader) dataLoader.resetQuery(newFilters);
    },
    [dataLoader]
  );

  const simpleFetchData = useCallback(async () => {
    if (!dataLoader.isDataLoading()) {
      await dataLoader.simpleLoadData();
      setNewData(dataLoader.data);
      setDataLoader(dataLoader);
    }
  }, [setNewData, setDataLoader]);

  return useMemo(
    () => ({ data, resetFilters, fetchData, fetchMoreData, simpleFetchData }),
    [data, resetFilters, fetchData, fetchMoreData, simpleFetchData]
  );
};
