import { OUTLIER_COLUMNS, OUTLIER_FILTERS } from '@features/admin/outliers/outliersProtos';
import { useOutlierActionsColumns } from '@features/admin/outliers/useOutlierActionsColumns';
import { useAdminApi } from '@hooks/useAdminApi';
import { BlotterWidget } from '@shared/components/BotterWidget';
import { usePageTitle } from '@shared/hooks/usePageTitle';
import { ColDef } from 'ag-grid-community';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const getRowId = ({ data }): string => data?.id?.toString();

const defaultColDef = (navigate: NavigateFunction) => {
  return {
    sortable: true,
    filter: false,
    floatingFilter: false,
    resizable: true,
    width: 150,
    onCellClicked(event) {
      if (event.column.getColId() === 'symbol') {
        navigate(`/admin/outliers/${event.data.symbol}`);
      }
    },
    cellStyle(params) {
      if (params.column.getColId() === 'symbol') {
        return { cursor: 'pointer' };
      }
      return null;
    },
  } as ColDef;
};

export const OutliersAdminPage = () => {
  usePageTitle('Outliers - Admin');
  const navigate = useNavigate();
  const { apiClient } = useAdminApi();

  const dataLoader = apiClient?.outliersLoader();
  const COLUMNS = useOutlierActionsColumns(OUTLIER_COLUMNS);

  if (!dataLoader) return null;

  return (
    <BlotterWidget
      dataLoader={dataLoader}
      columnDefs={COLUMNS}
      filters={OUTLIER_FILTERS}
      getRowId={getRowId}
      defaultColDef={defaultColDef(navigate)}
    />
  );
};
