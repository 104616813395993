import { streamProductRisk } from '@services/context';
import { ProductRiskCallback } from '@services/streamProductRiskService';
import { useEffect } from 'react';

// hook for subscribing to productSymbol and AccountID for risk
export const useProductRiskStreaming = (callback: ProductRiskCallback, symbol: string, accountId: string) => {
  useEffect(() => {
    streamProductRisk.subscribe(symbol, accountId, callback);

    return () => {
      streamProductRisk.unsubscribe(symbol, accountId, callback);
    };
  }, [symbol, accountId, callback]);
};
