import { TradingABNReports } from '@features/admin/trading/components/TradingABNReports';
import { TradingExecutions } from '@features/admin/trading/components/TradingExecutions';
import { TradingOrders } from '@features/admin/trading/components/TradingOrders';
import { TradingRisk } from '@features/admin/trading/components/TradingRisk';
import { TradingHedger } from '@features/admin/trading/components/hedger/TradingHedger';
import { Paper, Stack, Tab, Tabs } from '@mui/material';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { usePageTitle } from '@shared/hooks/usePageTitle';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';

type Tab = { label: string; value: string; Component: React.ReactNode };
const TradingAdminTabs: Tab[] = [
  { label: 'Risk', value: 'risk', Component: <TradingRisk /> },
  { label: 'Orders', value: 'orders', Component: <TradingOrders /> },
  { label: 'Executions', value: 'executions', Component: <TradingExecutions /> },
  { label: 'ABN Reports', value: 'abn-reports', Component: <TradingABNReports /> },
  { label: 'Hedger', value: 'hedger', Component: <TradingHedger /> },
];

export const TradingAdminPage = () => {
  usePageTitle('Trading Admin');

  const user = useUserContext();
  const { activeTab } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState<Tab | undefined>();

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    const tab = TradingAdminTabs.find(tab => tab.value === newValue);
    if (tab) {
      setSelectedTab(tab);
      setSearchParams({});
    }
  };

  useEffect(() => {
    if (!activeTab) setSelectedTab(TradingAdminTabs[0]);
    else {
      const tab = TradingAdminTabs.find(tab => tab.value === activeTab);
      if (tab) {
        setSelectedTab(tab);
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (selectedTab) {
      const params = searchParams.toString();
      navigate(`/admin/trading/${selectedTab.value}${params ? `?${params}` : ''}`);
    }
  }, [selectedTab]);

  if (!user?.is_trading_admin) return <NotFoundPage />;

  return selectedTab ? (
    <Stack display="flex" flexDirection="column" flex={1} gap={1} padding={2}>
      <Stack display="flex" flexDirection="row" gap={1}>
        <Paper style={{ flex: 9 }}>
          <Tabs value={selectedTab.value} onChange={onTabChange} variant="scrollable" scrollButtons="auto">
            {TradingAdminTabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} style={{ fontSize: 11 }} />
            ))}
          </Tabs>
        </Paper>
      </Stack>
      <Paper sx={{ flex: 1 }}>{selectedTab.Component}</Paper>
    </Stack>
  ) : null;
};
