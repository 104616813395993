import { Box, CircularProgress } from '@mui/material';
import { CotData } from '@protos/cot';
import { FluxCountdown } from '@shared/components/FluxCountdown/FluxCountdown';
import '@shared/styles/cot-page.scss';
import { generateDtdContent, transformCotData } from '@utils/cotUtils';
import { useCallback, useState } from 'react';

const FREE_BASE_API = 'https://fa-data-prod.azurewebsites.net/api/fluxcftcfree';
const FREE_BASE_API_CODE = 'PjDvXGNLG9IUoeDTp6t7JBXHBjftX2AOR0KI7OeU-jYTAzFuWvW5iw%3D%3D';
const FREE_API = `${FREE_BASE_API}?code=${FREE_BASE_API_CODE}`;
const countdownEndDateTime = new Date('2024-08-01T19:30:00Z');

export const SecretCotPage = () => {
  const [cotData, setCotData] = useState<Record<string, CotData>>();
  const [cotDataIsLoading, setCotDataIsLoading] = useState<boolean>(true);

  const fetchCotData = useCallback(() => {
    fetch(FREE_API)
      .then(response => response.json())
      .then(data => {
        setCotData(transformCotData(data));
        setCotDataIsLoading(false);
      });
  }, []);

  const onCountdownComplete = useCallback(() => {
    fetchCotData();
  }, [fetchCotData]);

  const Completionist = useCallback(() => {
    if (cotDataIsLoading)
      return (
        <Box sx={{ width: '100%', height: '100vh', alignContent: 'center', textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      );
    if (!cotData) return null;
    return (
      <div className="table-container">
        <pre className="dtd-file">{generateDtdContent(cotData)}</pre>
      </div>
    );
  }, [cotDataIsLoading, cotData]);

  return (
    <FluxCountdown
      title="Onyx Disaggregated Commitment of Traders Report"
      end={countdownEndDateTime}
      Completionist={Completionist}
      onCountdownComplete={onCountdownComplete}
    />
  );
};
