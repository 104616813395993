import {
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Popover,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonOwnProps,
  Typography,
  styled,
} from '@mui/material';
import { priceFormatterService } from '@services/PriceFormatterService';
import { streamRFQ } from '@services/context';
import { NumberInput } from '@shared/components/NumberInput';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useStreamRFQ } from '@shared/hooks/useStreamRFQ';
import { ProductTenor } from '@shared/protos/product';
import { RFQPriceAmount, RFQTicker } from '@shared/protos/ticker';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SelectedTradingProduct } from './hooks/useTradingGrid';

const buyBlue = '#4189E8';
const sellRed = '#CD4B4B';
const MIN_SIZE_VALUE = 0;

type TradingToggleButtonProps = {
  buttonColor: string;
  isAligned?: boolean;
  isSelected?: boolean;
} & ToggleButtonOwnProps;

const TradingToggleButton = styled((props: TradingToggleButtonProps) => <ToggleButton {...props} />, {
  shouldForwardProp: (propName: string) => propName !== 'buttonColor' && propName !== 'isAligned' && propName !== 'isSelected',
})<TradingToggleButtonProps>(({ buttonColor, isAligned, isSelected }) => ({
  minWidth: 110,
  maxHeight: 100,
  display: 'flex',
  flexDirection: 'column',
  alignItems: isAligned ? 'flex-end' : 'flex-start',
  color: 'grey',
  backgroundColor: isSelected ? `${buttonColor} !important` : 'transparent !important',
  '&.MuiToggleButton-root:hover': {
    color: 'white',
    backgroundColor: `${buttonColor} !important`,
    opacity: isSelected ? 1 : 0.4,
  },
}));

const DEFAULT_TRADE_SIZE = 1;

interface TradingPopOverProps {
  anchorEl: any;
  onClose: () => void;
  selectedTradingProduct: SelectedTradingProduct;
  selectedTradingAccount: string | undefined;
  selectedExchange: string;
  isBidPopover: boolean;
}

export const TradingPopOver = ({
  anchorEl,
  onClose,
  selectedTradingProduct,
  isBidPopover,
  selectedTradingAccount,
  selectedExchange,
}: TradingPopOverProps) => {
  const sizeInputRef = useRef<HTMLDivElement>(null);
  const { rowData, symbol } = selectedTradingProduct;
  const { tenors } = useUserProductsAndTenorsContext();

  const user = useUserContext();

  const [userTradeLimits, setUserTradeLimits] = useState<Record<string, number>>({});
  const [isTradeLimitBreached, setIsTradeLimitBreached] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tradeSize, setTradeSize] = useState<number>(DEFAULT_TRADE_SIZE);
  const [debouncedTradeSize, setDebouncedTradeSize] = useState<number>(DEFAULT_TRADE_SIZE);
  const [isBuySideTrade, toggleIsBuySideTrade] = useState(isBidPopover);
  const [higherOrderBackTenor, setHigherOrderBackTenor] = useState<ProductTenor | undefined>(undefined);

  const [bid, setBid] = useState<RFQPriceAmount | undefined>(undefined);
  const [ask, setAsk] = useState<RFQPriceAmount | undefined>(undefined);

  const [isLoadingNewPrices, setIsLoadingNewPrices] = useState<boolean>(true);

  const id = useMemo(() => (isOpen ? 'trading-popover' : undefined), [isOpen]);
  const product = useMemo(
    () => (rowData ? (typeof symbol === 'string' ? symbol.split(rowData.tenorId)[0] : symbol.front.split(rowData.tenorId)[0]) : undefined),
    [symbol, rowData]
  );
  const backTenor = useMemo(
    () =>
      higherOrderBackTenor ??
      (typeof symbol !== 'string' && product ? tenors.find(tenor => tenor.code === symbol.back.split(product)[1]) : undefined),
    [symbol, product, tenors, higherOrderBackTenor]
  );
  const spreadTradeFilterOptions = useMemo(() => {
    if (rowData && tenors.length) {
      const sameFrequencyTenors = tenors.filter(option => option.frequency === rowData?.tenorFrequency);
      const currentBackTenorIndex = sameFrequencyTenors.findIndex(option => option.code === rowData?.tenorId);
      return sameFrequencyTenors.slice(currentBackTenorIndex + 1, sameFrequencyTenors.length);
    }
    return [];
  }, [rowData, tenors]);
  const rfqSubscriptionSymbol = useMemo(() => {
    if (!symbol) return '';
    const isSpreadSymbol = typeof symbol !== 'string';

    if (isSpreadSymbol) {
      const { front, back } = symbol;
      if (!front || !back || !debouncedTradeSize || !product) return '';

      return `${front}-${higherOrderBackTenor?.code ? `${product}${higherOrderBackTenor.code}` : back}-${debouncedTradeSize}`;
    } else {
      const productSymbol = symbol.split('-')[0];
      if (!productSymbol || !debouncedTradeSize) return '';

      return `${productSymbol}-${debouncedTradeSize}`;
    }
  }, [symbol, debouncedTradeSize, higherOrderBackTenor, product]);
  const showUserInputError = useMemo(() => isTradeLimitBreached || tradeSize <= 0 || tradeSize === undefined, [tradeSize, isTradeLimitBreached]);

  const onTradeSizeChange = useCallback(
    (_, newValue) => {
      if (product) {
        setTradeSize(newValue);
        const productBeingTraded = typeof symbol === 'string' ? product : `${product}spr`;
        if (newValue > userTradeLimits[productBeingTraded.toLocaleLowerCase()]) {
          setIsTradeLimitBreached(true);
        } else if (isTradeLimitBreached) {
          setIsTradeLimitBreached(false);
        }
      }
    },
    [product, symbol, userTradeLimits, isTradeLimitBreached]
  );

  const onTradeSideChange = useCallback(
    (_, newBuySideState: boolean) => {
      if (newBuySideState !== null) {
        toggleIsBuySideTrade(!isBuySideTrade);
      }
    },
    [isBuySideTrade]
  );

  const onBackTenorChange = useCallback(
    (newBackTenor: string | null) => {
      if (newBackTenor) {
        setIsLoadingNewPrices(true);
        setHigherOrderBackTenor(tenors.find(tenor => tenor.code === newBackTenor));
      }
    },
    [tenors]
  );

  const onPlaceTrade = useCallback(() => {
    const askPrice = ask?.price;
    const bidPrice = bid?.price;

    const tradeAskPrice = askPrice?.toString();
    const tradeBidPrice = bidPrice?.toString();

    if (tradeAskPrice && tradeBidPrice && rfqSubscriptionSymbol && debouncedTradeSize) {
      const [front, back, size] = rfqSubscriptionSymbol.split('-');
      const isSpreadSymbol = typeof symbol !== 'string' && front && back;

      streamRFQ.placeOrder({
        symbol: isSpreadSymbol ? { front, back } : symbol,
        amount: debouncedTradeSize.toString(),
        side: isBuySideTrade ? 'buy' : 'sell',
        price: isBuySideTrade ? tradeAskPrice : tradeBidPrice,
        timestamp_millis: Date.now(),
        // exchange: selectedExchange,
        ...(selectedTradingAccount ? { account_id: selectedTradingAccount } : {}),
      });
    }
  }, [ask, bid, debouncedTradeSize, isBuySideTrade, rfqSubscriptionSymbol, selectedTradingAccount, symbol, selectedExchange]);

  useEffect(() => {
    if (user && user.trade_limits.length) {
      const tradeLimits = user.trade_limits;
      setUserTradeLimits(tradeLimits.reduce((acc, limit) => ({ ...acc, [limit.symbol.toLocaleLowerCase()]: limit.limit }), {}));
    }
  }, [user]);

  useEffect(() => {
    toggleIsBuySideTrade(isBidPopover);
  }, [isBidPopover]);

  useEffect(() => {
    setIsOpen(Boolean(anchorEl));
  }, [anchorEl]);

  useEffect(() => {
    if (isOpen && sizeInputRef.current) {
      sizeInputRef.current?.focus();
    }
  }, [isOpen, sizeInputRef.current]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!tradeSize) return;
      setIsLoadingNewPrices(true);
      setDebouncedTradeSize(tradeSize);
    }, 250);

    return () => clearTimeout(timeoutId);
  }, [tradeSize]);

  useStreamRFQ(
    (incomingTicker: RFQTicker) => {
      const { symbol } = incomingTicker;

      //Verify Incoming Ticker
      if (typeof symbol === 'string') {
        if (rfqSubscriptionSymbol.split('-').length > 2 || symbol !== rfqSubscriptionSymbol.split('-')[0]) return;
      } else {
        const { front: incomingFront, back: incomingBack } = symbol;
        const [front, back, size] = rfqSubscriptionSymbol.split('-');
        if (incomingFront !== front || incomingBack !== back) return;
      }

      if (incomingTicker.bid && incomingTicker.ask) {
        setBid(incomingTicker.bid);
        setAsk(incomingTicker.ask);
        setIsLoadingNewPrices(false);
      }
    },
    rfqSubscriptionSymbol,
    selectedExchange
  );

  if (!rowData || !symbol) return;

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transitionDuration={5000}
      role="trading-dialog"
    >
      {bid && ask && product ? (
        <Stack direction="column" flex={1} minHeight={350} minWidth={250} alignItems="center">
          <Typography variant="h6" style={{ padding: 1, fontSize: 11 }} tabIndex={-1}>
            TRADE
          </Typography>
          <Divider flexItem />
          <Stack direction="column" width="100%" flex={1} padding={2}>
            {typeof symbol === 'string' ? (
              <Stack direction="row" width="100%" alignItems="flex-end" justifyContent="space-around" marginBottom={1}>
                <Stack direction="column">
                  <Typography variant="body1" tabIndex={-1}>
                    {product.toLocaleUpperCase()}
                  </Typography>
                  <Typography variant="h5" tabIndex={-1}>
                    {rowData.tenorName.toLocaleUpperCase()}
                  </Typography>
                </Stack>
                <Typography
                  style={{ color: '#FFFFFF', fontSize: 11, backgroundColor: '#585858', borderRadius: 3, paddingLeft: 3, paddingRight: 3 }}
                  tabIndex={-1}
                >
                  {symbol.toLocaleUpperCase()}
                </Typography>
              </Stack>
            ) : (
              <Stack display="flex" direction="column" width="100%" alignItems="center" alignContent="center" marginBottom={2} gap={1}>
                <Typography variant="h5" tabIndex={-1}>
                  {`${product}spr`.toLocaleUpperCase()}
                </Typography>
                <Stack display="flex" direction="row" width="100%" alignItems="center" justifyContent="space-evenly">
                  <Stack direction="row" width="100%" justifyContent="space-evenly">
                    <Typography variant="h6" tabIndex={-1}>
                      {rowData.tenorName.toLocaleUpperCase()}
                    </Typography>
                    <Typography variant="h5" tabIndex={-1}>
                      /
                    </Typography>
                  </Stack>
                  {backTenor && (
                    <Select
                      size="small"
                      value={backTenor?.code}
                      onChange={(event: any) => onBackTenorChange(event.target.value)}
                      MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                      sx={{ marginRight: 3 }}
                      tabIndex={-1}
                    >
                      {spreadTradeFilterOptions.map(option => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.display.toLocaleUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Stack>
              </Stack>
            )}
            <Stack direction="column" flex={1} alignItems="center" gap={4} width="100%">
              <ToggleButtonGroup value={isBuySideTrade} exclusive onChange={onTradeSideChange}>
                <TradingToggleButton value={false} buttonColor={sellRed} isAligned sx={{ boxShadow: 5 }} isSelected={!isBuySideTrade}>
                  <Typography style={{ fontSize: 11 }} tabIndex={-1}>
                    Sell
                  </Typography>
                  <Typography variant={!isBuySideTrade ? 'h4' : 'body1'} tabIndex={-1} data-testid="bid-price">
                    {priceFormatterService.formatProductPrice(bid.price)}
                  </Typography>
                  <Typography style={{ fontSize: 11 }} tabIndex={-1} data-testid="bid-amount">
                    {`${bid.amount} Lots`}
                  </Typography>
                </TradingToggleButton>
                <TradingToggleButton value={true} buttonColor={buyBlue} sx={{ boxShadow: 5 }} isSelected={isBuySideTrade}>
                  <Typography style={{ fontSize: 11 }} tabIndex={-1}>
                    Buy
                  </Typography>
                  <Typography variant={isBuySideTrade ? 'h4' : 'body1'} tabIndex={-1} data-testid="ask-price">
                    {priceFormatterService.formatProductPrice(ask.price)}
                  </Typography>
                  <Typography style={{ fontSize: 11 }} tabIndex={-1} data-testid="ask-amount">
                    {`${ask.amount} Lots`}
                  </Typography>
                </TradingToggleButton>
              </ToggleButtonGroup>
              <Typography
                style={{
                  fontSize: 11,
                  position: 'absolute',
                  marginTop: 81,
                  color: '#585858',
                  backgroundColor: '#FFFFFF',
                  borderRadius: 3,
                  paddingLeft: 3,
                  paddingRight: 3,
                }}
                tabIndex={-1}
                marginTop={0}
              >
                {`${(Number.parseFloat(ask.price) - Number.parseFloat(bid.price)).toFixed(3)}`}
              </Typography>
              <Stack direction="column" flex={1} alignItems="center" gap={6}>
                <Stack direction="column" flex={1} alignItems="center">
                  <NumberInput
                    ref={sizeInputRef}
                    label="RFQ Lots"
                    value={tradeSize}
                    min={MIN_SIZE_VALUE}
                    onChange={onTradeSizeChange}
                    tabIndex={1}
                    error={showUserInputError}
                    allowClearable
                  />
                  {isTradeLimitBreached && (
                    <Typography fontSize={11} color="error" tabIndex={-1}>
                      Trade size exceeds trading limit
                    </Typography>
                  )}
                </Stack>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onPlaceTrade}
                  tabIndex={2}
                  disabled={isLoadingNewPrices || showUserInputError}
                  data-testid="place-order-button"
                >
                  Place Order
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack direction="column" flex={1} justifyContent="center" alignItems="center" minHeight={350} minWidth={250}>
          <CircularProgress />
        </Stack>
      )}
    </Popover>
  );
};
