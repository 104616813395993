import { WidgetProps } from '@features/dashboard/types';
import { Box, Divider } from '@mui/material';
import { useMemo } from 'react';
import Widget, { Content, Header } from '../Widget';
import Settings from '../settings/Settings';
import { SettingsFormResultLayout } from '../settings/SettingsFormResultLayout';
import { ProductsSettingsForm } from '../settings/products/ProductsSettingsForm';
import { TenorsSettingsForm } from '../settings/tenors/TenorsSettingsForm';
import PricesProvider from './PricesContext';
import PricesGrid from './components/PricesGrid';
import { PricesWidgetPayload } from './types';

type Props = WidgetProps & {
  payload?: PricesWidgetPayload;
};

export const DEFAULT_PRICES_ROLLING_SETTINGS = { daily: 0, weekly: 0, monthly: 0, yearly: 0, quarterly: 0 };

export default function Prices({ payload, ...otherProps }: Props) {
  const { id, title } = otherProps;

  const initialValues = useMemo(
    () => ({
      selectedColumns: payload?.selectedColumns ?? [],
      selectedRows: payload?.selectedRows ?? [],
      columnsOrder: payload?.columnsOrder ?? [],
      userRollingRowSettings: payload?.userRollingRowSettings ?? DEFAULT_PRICES_ROLLING_SETTINGS,
      isOverrideRolling: payload?.isOverrideRolling ?? false,
    }),
    [payload]
  );

  return (
    <Widget {...otherProps}>
      <PricesProvider widgetId={id} initialValues={initialValues}>
        <Header title={title}>
          <Settings id={`prices-settings-${id}`} widgetId={id}>
            <Box display="flex" flex={1} flexDirection="row" gap={2} minHeight={800}>
              <SettingsFormResultLayout title="Products" Form={<ProductsSettingsForm />} />
              <Divider orientation="vertical" flexItem />
              <SettingsFormResultLayout title="Tenors" Form={<TenorsSettingsForm />} />
            </Box>
          </Settings>
        </Header>
        <Content>
          <PricesGrid />
        </Content>
      </PricesProvider>
    </Widget>
  );
}
