import { Snackbar, Typography, styled } from '@mui/material';
import IconButton, { IconButtonProps, iconButtonClasses } from '@mui/material/IconButton';
import { useCallback, useState } from 'react';
import { copyToClipboard } from '../utils';

type Props = {
  message: string;
  textToCopied: string;
} & IconButtonProps;

const StyledCopyButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.text.primary,
  },
  [`&.${iconButtonClasses.disabled}`]: {
    color: '#09fb9d',
  },
}));

export const CopyButton: React.FC<Props> = ({ message, textToCopied, onClick, ...buttonProps }) => {
  const [open, setOpen] = useState(false);

  const copy = useCallback(e => {
    setOpen(true);
    copyToClipboard(textToCopied);
    if (onClick) onClick(e);
  }, []);

  return (
    <>
      <StyledCopyButton {...buttonProps} onClick={copy} disabled={open}>
        <i className={open ? 'ri-check-double-fill' : 'ri-file-copy-line'} />
      </StyledCopyButton>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        onClose={() => setOpen(false)}
        message={<Typography>{message}</Typography>}
        autoHideDuration={1000}
      />
    </>
  );
};
