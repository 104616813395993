import { useApi } from '@hooks/useApi';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Analytics, Trending } from '@protos/analytics';
import { Product, ProductTenor } from '@protos/product';
import { numberService } from '@services/NumberService';
import theme from '@shared/themes/darkTheme';
import { getContractForSymbolInfo } from '@utils/symbol';
import { ITooltipParams } from 'ag-grid-community';
import { useCallback, useEffect, useState } from 'react';

type PicesAnalyticsTooltipProps = {
  columnId: string;
  productMap: Record<string, Product>;
  tenorMap: Record<string, ProductTenor>;
} & ITooltipParams;

const getTrendingIcon = (trending: Trending) => {
  if (!trending) return;
  if (trending === 'Up') return { className: 'ri-arrow-up-line', color: theme.palette.background.buy };
  if (trending === 'Down') return { className: 'ri-arrow-down-line', color: theme.palette.background.sell };
  return { className: 'ri-arrow-right-line', color: 'white' };
};

export const PicesAnalyticsTooltip = ({ rowIndex, api: gridApi, columnId, productMap, tenorMap }: PicesAnalyticsTooltipProps) => {
  const { apiClient } = useApi();

  const [analyticsData, setAnalyticsData] = useState<Analytics | undefined>();

  const getContractAnalytics = useCallback(
    async (contractSymbol: string): Promise<Analytics> => {
      try {
        const response = await apiClient?.getContractAnalytics(contractSymbol);
        const analyticsResponseData = response || ({} as Analytics);

        setAnalyticsData(analyticsResponseData);
        return analyticsResponseData;
      } catch (err: any) {
        console.error(err.data.error || 'Something went wrong');
        setAnalyticsData({} as Analytics);
        return {} as Analytics;
      }
    },
    [apiClient]
  );

  useEffect(() => {
    if ((rowIndex !== 0 && !rowIndex) || !gridApi || !apiClient) return;
    const row = gridApi?.getDisplayedRowAtIndex(rowIndex);
    if (!row || !columnId) return;
    const data = row?.data;
    const cellValue = row?.data[columnId];

    if (cellValue) {
      const contractSymbol = getContractForSymbolInfo(columnId, data, productMap, tenorMap);

      if (contractSymbol) {
        getContractAnalytics(contractSymbol);
      }
    }
  }, [apiClient, getContractAnalytics]);

  if (rowIndex !== 0 && !rowIndex)
    return (
      <Box sx={{ backgroundColor: theme.palette.secondary.dark, padding: 1, fontSize: 11, width: 'max-content' }}>
        {productMap[columnId].full_name}
      </Box>
    );

  if (analyticsData && Object.keys(analyticsData).length === 0) return '';

  if (!analyticsData) return <Paper style={{ minWidth: 130, padding: 10, textAlign: 'center' }}>Finding analytics...</Paper>;

  return +analyticsData.blotteredtotalvolume30d > 0 ? (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        width: 250,
        gap: 3,
        flex: 1,
      }}
    >
      <Typography variant="h6" fontSize={11} width="100%">
        {`${analyticsData.symbol.toLocaleUpperCase()}`}
      </Typography>
      <div>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="caption" fontSize={9} width="100%">
            Market Position
          </Typography>
          <Box display="flex" alignContent="center">
            <Typography variant="caption" fontSize={9} width="100%">
              Long
            </Typography>
            <i
              className={getTrendingIcon(analyticsData.trendingblotteredmarketlongpositionratio)?.className}
              style={{
                alignSelf: 'center',
                color: getTrendingIcon(analyticsData.trendingblotteredmarketlongpositionratio)?.color,
              }}
            />
            <Typography variant="caption" fontSize={9} width="100%">
              Short
            </Typography>
            <i
              className={getTrendingIcon(analyticsData.trendingblotteredmarketshortpositionratio)?.className}
              style={{
                alignSelf: 'center',
                color: getTrendingIcon(analyticsData.trendingblotteredmarketshortpositionratio)?.color,
              }}
            />
          </Box>
        </Box>
        <div>
          {analyticsData.blotteredmarketlongpositionratio !== null && analyticsData.blotteredmarketshortpositionratio !== null ? (
            <>
              <Box display="flex">
                <div
                  style={{
                    backgroundColor: theme.palette.background.buy,
                    width: `${+analyticsData.blotteredmarketlongpositionratio * 100}%`,
                    height: 25,
                    alignContent: 'center',
                    textAlign: 'center',
                    borderRadius: +analyticsData.blotteredmarketlongpositionratio !== 1 ? '5px 0 0 5px' : '5px 5px 5px 5px',
                    fontWeight: 800,
                  }}
                >
                  <div style={{ position: 'sticky', fontSize: 9 }}>
                    {+analyticsData.blotteredmarketlongpositionratio !== 0
                      ? `${Math.round(+analyticsData.blotteredmarketlongpositionratio * 100)}% LONG`
                      : null}
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: theme.palette.background.sell,
                    width: `${+analyticsData.blotteredmarketshortpositionratio * -100}%`,
                    height: 25,
                    alignContent: 'center',
                    textAlign: 'center',
                    borderRadius: +analyticsData.blotteredmarketshortpositionratio !== 1 ? '0 5px 5px 0' : '5px 5px 5px 5px',
                    fontWeight: 800,
                  }}
                >
                  <div style={{ position: 'sticky', fontSize: 9 }}>
                    {+analyticsData.blotteredmarketshortpositionratio !== 0
                      ? `${Math.round(+analyticsData.blotteredmarketshortpositionratio * -100)}% SHORT`
                      : null}
                  </div>
                </div>
              </Box>
              <Box display="flex" flex={1} gap={1}>
                <Typography variant="caption" fontSize={9} fontWeight={800} color={theme.palette.background.buy}>
                  {`Long ${numberService.formatPrice(+analyticsData.blotteredmarketlongposition)}`}
                </Typography>
                <Typography variant="caption" fontSize={9} fontWeight={800}>
                  {` / `}
                </Typography>
                <Typography variant="caption" fontSize={9} fontWeight={800} color={theme.palette.background.sell}>
                  {`Short ${numberService.formatPrice(+analyticsData.blotteredmarketshortposition)}`}
                </Typography>
              </Box>
            </>
          ) : (
            <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
              No data
            </Typography>
          )}
        </div>
      </div>
      <div>
        <Typography variant="caption" fontSize={9} width="100%">
          OI Saturation
        </Typography>
        <i
          className={getTrendingIcon(analyticsData.trendingoi)?.className}
          style={{ alignSelf: 'center', marginLeft: 2, color: getTrendingIcon(analyticsData.trendingoi)?.color }}
        />
        <Box display="flex">
          {analyticsData.oi !== null && analyticsData.max5yroi !== null ? (
            <>
              <div
                style={{
                  backgroundColor: +analyticsData.oi <= +analyticsData.max5yroi ? '#7caceb' : '#d46e6e',
                  width:
                    +analyticsData.oi <= +analyticsData.max5yroi
                      ? `${(+analyticsData.oi / +analyticsData.max5yroi) * 100}%`
                      : `${(+analyticsData.max5yroi / +analyticsData.oi) * 100}%`,
                  height: 25,
                  borderRight: '1px solid white',
                  alignContent: 'center',
                  textAlign: 'center',
                  borderRadius: '5px 0 0 5px',
                  fontWeight: 800,
                }}
              >
                <div style={{ position: 'sticky', fontSize: 9 }}>
                  {+analyticsData.oi <= +analyticsData.max5yroi ? `${Math.round((+analyticsData.oi / +analyticsData.max5yroi) * 100)}%` : ``}
                </div>
              </div>
              <div
                style={{
                  backgroundColor: +analyticsData.oi <= +analyticsData.max5yroi ? theme.palette.secondary.main : '#d46e6e',
                  width:
                    +analyticsData.oi <= +analyticsData.max5yroi
                      ? `${(1 - +analyticsData.oi / +analyticsData.max5yroi) * 100}%`
                      : `${(1 - +analyticsData.max5yroi / +analyticsData.oi) * 100}%`,
                  height: 25,
                  alignContent: 'center',
                  textAlign: 'center',
                  borderRadius: '0 5px 5px 0',
                  fontWeight: 800,
                }}
              >
                <div style={{ position: 'sticky', fontSize: 9 }}>
                  {+analyticsData.oi <= +analyticsData.max5yroi ? `` : `${Math.round((+analyticsData.oi / +analyticsData.max5yroi) * 100)}%`}
                </div>
              </div>
            </>
          ) : (
            <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
              No data
            </Typography>
          )}
        </Box>
      </div>
      <div>
        <Typography variant="caption" fontSize={9} width="100%">
          Rolling Volatility
        </Typography>
        <Box display="flex" flex={1} gap={1}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme.palette.secondary.main,
              padding: 5,
              borderRadius: 5,
              width: '100%',
            }}
          >
            <Box display="flex">
              <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
                7D σ
              </Typography>
              <i
                className={getTrendingIcon(analyticsData.trendingpricestd7)?.className}
                style={{ alignSelf: 'center', marginLeft: 2, color: getTrendingIcon(analyticsData.trendingpricestd7)?.color }}
              />
            </Box>
            <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
              {Number.parseFloat(analyticsData.pricestd7).toFixed(2)}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              backgroundColor: theme.palette.secondary.main,
              padding: 5,
              borderRadius: 5,
              width: '100%',
            }}
          >
            <Box display="flex">
              <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
                30D σ
              </Typography>
              <i
                className={getTrendingIcon(analyticsData.trendingpricestd30)?.className}
                style={{ alignSelf: 'center', marginLeft: 2, color: getTrendingIcon(analyticsData.trendingpricestd30)?.color }}
              />
            </Box>
            <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
              {Number.parseFloat(analyticsData.pricestd30).toFixed(2)}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              backgroundColor: theme.palette.secondary.main,
              padding: 5,
              borderRadius: 5,
              width: '100%',
            }}
          >
            <Box display="flex">
              <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
                90D σ
              </Typography>
              <i
                className={getTrendingIcon(analyticsData.trendingpricestd90)?.className}
                style={{ alignSelf: 'center', marginLeft: 2, color: getTrendingIcon(analyticsData.trendingpricestd90)?.color }}
              />
            </Box>
            <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
              {Number.parseFloat(analyticsData.pricestd90).toFixed(2)}
            </Typography>
          </div>
        </Box>
      </div>
      <div>
        <Typography variant="caption" fontSize={9} width="100%">
          Entry Price (90D)
        </Typography>
        <Box display="flex" flex={1} gap={1}>
          {analyticsData.marketlongentryprice90d !== null && analyticsData.marketshortentryprice90d !== null ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  backgroundColor: theme.palette.background.buy,
                  padding: 5,
                  borderRadius: 5,
                  width: '100%',
                }}
              >
                <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
                  LONG
                </Typography>
                <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
                  {Number.parseFloat(analyticsData.marketlongentryprice90d).toFixed(2)}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  backgroundColor: theme.palette.background.sell,
                  padding: 5,
                  borderRadius: 5,
                  width: '100%',
                }}
              >
                <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
                  SHORT
                </Typography>
                <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
                  {Number.parseFloat(analyticsData.marketshortentryprice90d).toFixed(2)}
                </Typography>
              </div>
            </>
          ) : (
            <Typography variant="caption" fontSize={9} color="white" fontWeight={800}>
              No data
            </Typography>
          )}
        </Box>
      </div>
      <Button
        variant="text"
        onClick={() => window.open('https://www.onyxhub.co/dashboards/onyx-market-position/')}
        style={{ fontSize: 9, marginLeft: 'auto' }}
      >
        More Info
      </Button>
    </Paper>
  ) : null;
};
