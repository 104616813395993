import { useSettlementPricesSettings } from '@features/dashboard/widgets/settlement-prices/SettlementPricesContext';
import { getColId, mapSettlementTypeToHeaderName } from '@features/dashboard/widgets/settlement-prices/utils';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import ToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton';
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import { alpha, styled } from '@mui/material/styles';
import { SnapshotPeriod } from '@protos/user';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useScreenSize } from '@shared/hooks/useScreenSize';
import { SETTLEMENT_PERIOD, SettlementType } from '@shared/protos/settlementPrices';
import { ColumnState } from 'ag-grid-community';
import { useCallback, useState } from 'react';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    border: '2px solid transparent !important',
    backgroundColor: `${alpha(theme.palette.primary.main, 0.1)}!important`,
    color: alpha(theme.palette.text.secondary, 0.5),
    textTransform: 'none',
    '&:not(:first-of-type)': {
      marginLeft: '3px !important',
    },
    '&:first-of-type': {
      borderRadius: '0px',
    },
    '&:last-of-type': {
      borderRadius: '0px',
    },
    [`&.${toggleButtonClasses.selected}`]: {
      color: theme.palette.text.primary,
      backgroundColor: `${alpha(theme.palette.primary.main, 0.5)}!important`,
    },
    [`&.${toggleButtonClasses.disabled}`]: {
      border: 0,
    },
  },
}));

export default function ToggleSettlements() {
  const { gridRef, selectedSettlementTypes, selectedColIds, toggleSettlementTypes } = useSettlementPricesSettings();
  const { isMobile } = useScreenSize();
  const user = useUserContext();

  const [selectedSnapshots, setSelectedSnapshots] = useState<SettlementType[]>(selectedSettlementTypes);

  const getIsSnapshotPeriodEnabled = useCallback(
    (settlementType: string) => {
      return user?.snapshot_periods.includes(SETTLEMENT_PERIOD[SettlementType[settlementType] as SnapshotPeriod]);
    },
    [user]
  );

  const onSelectedSnapshotChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newSelectedSnapshots: SettlementType[]) => {
      if (newSelectedSnapshots.length === 0 || !gridRef?.current) {
        return;
      }

      const gridApi = gridRef.current.getGridApi();
      const columnsNewState = gridApi?.getColumnGroupState().reduce<ColumnState[]>((acc, { groupId }) => {
        if (!selectedColIds.includes(groupId)) {
          return acc;
        }

        return [
          ...acc,
          ...Object.keys(SettlementType).map(settlementType => ({
            colId: getColId(groupId, SettlementType[settlementType]),
            hide: !newSelectedSnapshots.includes(SettlementType[settlementType]),
          })),
        ];
      }, []);

      gridApi?.applyColumnState({ state: columnsNewState });

      setSelectedSnapshots(newSelectedSnapshots);

      const hasUpdatedSettlementTypes =
        newSelectedSnapshots.length !== selectedSettlementTypes.length ||
        (newSelectedSnapshots.length === selectedSettlementTypes.length &&
          newSelectedSnapshots.some(elem => !selectedSettlementTypes.includes(elem)));

      if (hasUpdatedSettlementTypes) toggleSettlementTypes(newSelectedSnapshots);
    },
    [selectedColIds, toggleSettlementTypes, selectedSettlementTypes]
  );

  return (
    <Stack>
      <StyledToggleButtonGroup value={selectedSnapshots} onChange={onSelectedSnapshotChange}>
        {Object.keys(SettlementType).map(settlementType => (
          <ToggleButton
            disabled={!getIsSnapshotPeriodEnabled(settlementType)}
            value={SettlementType[settlementType]}
            key={settlementType}
            aria-label={settlementType}
            size={isMobile ? 'small' : 'medium'}
          >
            <Typography fontSize="11px">{mapSettlementTypeToHeaderName(SettlementType[settlementType])}</Typography>
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Stack>
  );
}
