import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Checkbox, Stack, Switch, TextField, Typography } from '@mui/material';
import { IconComponent } from '@shared/components/IconComponent';
import { NumberInput } from '@shared/components/NumberInput';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { ProductTenor } from '@shared/protos/product';
import theme from '@shared/themes/darkTheme';
import { useCallback, useState } from 'react';
import { useSettlementPricesSettings } from '../../settlement-prices/SettlementPricesContext';
import { DEFAULT_ALLOWED_PRICING_TENORS } from '../../shared/hooks/usePricesDefaultFilters';
import { RollingRowSettings } from '../../shared/types';
import { ROLLING_LABEL_MAP, filterOptions, getRollingNumberInputMinMaxForSettings } from '../utils';

const icon = <IconComponent name="ri-checkbox-blank-line" />;
const checkedIcon = <IconComponent name="ri-checkbox-line" />;
const ROLLING_TENORS = ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'];

export const SettlementsTenorsSettingsForm = () => {
  const { addRow, rollingRowSettings, updateRollingSettings, toggleRow, filteredSelectedTenors, isOverrideRolling, toggleIsOverrideRolling } =
    useSettlementPricesSettings();
  const { tenors } = useUserProductsAndTenorsContext();

  const [searchInput, setSearchInput] = useState('');

  const onChangeValues = useCallback(
    (selectedValues: ProductTenor[] | null) => {
      // Remove tenors that are not selected anymore
      const deltaValues = filteredSelectedTenors.filter(row => !selectedValues?.find(elem => elem.code === row.code));
      if (deltaValues.length) {
        deltaValues.forEach(removedValue => {
          toggleRow(removedValue.code);
        });
      }

      // Add tenors that are selected
      if (selectedValues?.length && addRow) {
        selectedValues.forEach(selectedValue => {
          addRow(selectedValue.code);
        });
      }
    },
    [addRow, toggleRow, filteredSelectedTenors]
  );

  const onRollingTenorValueChange = useCallback(
    (frequency: string, newValue: number | undefined) => {
      if (newValue !== undefined && newValue >= 0 && rollingRowSettings) {
        updateRollingSettings({ ...rollingRowSettings, [frequency]: newValue });
      }
    },
    [updateRollingSettings, rollingRowSettings]
  );

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap" useFlexGap>
        {ROLLING_TENORS.map(tenor => (
          <NumberInput
            key={tenor}
            disabled={isOverrideRolling}
            label={ROLLING_LABEL_MAP[tenor]}
            value={(rollingRowSettings ?? DEFAULT_ALLOWED_PRICING_TENORS)[tenor]}
            onChange={(e, value) => onRollingTenorValueChange(tenor, value)}
            min={getRollingNumberInputMinMaxForSettings(tenor as keyof RollingRowSettings).min}
            max={getRollingNumberInputMinMaxForSettings(tenor as keyof RollingRowSettings).max}
          />
        ))}
      </Stack>
      <Stack direction="row" alignItems="center" style={{ marginTop: 50 }}>
        <Switch checked={isOverrideRolling} size="small" onChange={() => toggleIsOverrideRolling()} />
        <Typography fontSize={11}>Enable Manual tenor selection</Typography>
      </Stack>
      <Autocomplete
        multiple
        disableCloseOnSelect
        disabled={!isOverrideRolling}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox size="small" icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            <Typography fontSize={11}>{option.display.toLocaleUpperCase()}</Typography>
          </li>
        )}
        value={filteredSelectedTenors}
        id="grouped-tenors-settlements"
        inputValue={searchInput}
        onInputChange={(event, newInputValue, reason) => {
          if (reason !== 'reset') {
            setSearchInput(newInputValue);
          }
        }}
        onChange={(event: any, newValues: ProductTenor[] | null) => onChangeValues(newValues)}
        size="small"
        options={[...tenors].sort((a, b) => a.frequency.localeCompare(b.frequency))}
        groupBy={option => option.frequency}
        getOptionLabel={option => option.display}
        renderInput={params => <TextField {...params} label="Add / remove a tenor" />}
        renderGroup={params => (
          <Accordion disableGutters>
            <AccordionSummary
              sx={{ backgroundColor: theme.palette.background.default }}
              expandIcon={<IconComponent name="ri-arrow-drop-down-line" iconStyle={{ fontSize: 22 }} />}
            >
              <Typography fontSize={11}>{params.group}</Typography>
            </AccordionSummary>
            <AccordionDetails>{params.children}</AccordionDetails>
          </Accordion>
        )}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        filterOptions={filterOptions}
      />
    </Stack>
  );
};
