import ManageAccountNavItem from '@features/base/private/components/ManageAccountNavItem';
import DashboardNavItem from '@features/dashboard/navbar/DashboardNavItem';
import WidgetNavItem from '@features/dashboard/navbar/WidgetNavItem';
import { Box, Tooltip, Typography } from '@mui/material';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import List, { ListProps } from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import { config } from '@services/context';
import theme from '@shared/themes/darkTheme';
import Logo from './Logo';
import HelpMenuNavItem from './components/HelpMenuNavItem';

type MenuProps = {
  to?: string;
  noAccess?: boolean;
} & IconButtonProps;

type SidebarButtonProps = {
  icon: string;
  title: string;
  to?: string;
} & IconButtonProps;

type StyledDrawerProps = {
  isDev?: boolean;
} & DrawerProps;

const StyledDrawer = styled(Drawer, { shouldForwardProp: propName => propName !== 'isDev' })<StyledDrawerProps>(({ theme, isDev }) => ({
  width: !isDev ? 44 : 54,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: !isDev ? 44 : 54,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.darker,
    border: 'none',
  },
}));

const StyledList = styled((props: ListProps) => <List component="nav" {...props} />)(() => ({
  height: '100%',
  paddingTop: '11px',
}));

const Spacing = styled('div')({
  marginTop: 'auto',
});

export const StyledMenuItem = styled(IconButton, { shouldForwardProp: propName => propName !== 'noAccess' })<MenuProps>(({ theme, noAccess }) => ({
  color: noAccess ? alpha(theme.palette.text.secondary, 0.3) : theme.palette.text.secondary,
  fontSize: 23,
  width: 34,
  height: 34,
  borderRadius: '2px',
  justifyItems: 'baseline',
  '&:hover, &.active': {
    color: noAccess ? alpha(theme.palette.text.secondary, 0.3) : theme.palette.text.primary,
    backgroundColor: '#242627',
  },
  '&:not([type=button]) > span': {
    alignSelf: 'flex-end',
  },
}));

export function SidebarButton({ icon, title, to, ...buttonProps }: SidebarButtonProps) {
  return (
    <Tooltip title={title} placement="right" enterDelay={800}>
      <StyledMenuItem to={to} {...buttonProps}>
        <i className={icon} />
      </StyledMenuItem>
    </Tooltip>
  );
}

export default function Sidebar() {
  const isDev = config.isDev;

  return (
    <StyledDrawer open variant="permanent" anchor="left" id="flux-sidebar-permanent" isDev={isDev}>
      <Box width="100%" height="100%" display="flex">
        {isDev && (
          <Box display="flex" flexDirection="column" bgcolor={theme.palette.background.buy} justifyContent="center" alignItems="center" width={10}>
            <Typography
              sx={{
                textOrientation: 'sideways',
                writingMode: 'vertical-lr',
                transform: `scale(-1)`,
                fontSize: 9,
                color: 'white',
                fontWeight: 800,
                marginBottom: 2,
              }}
            >
              DEV
            </Typography>
          </Box>
        )}
        <Stack width="100%" height="100%">
          <Logo />
          <StyledList>
            <Stack flex={1} height="100%" alignItems="center" rowGap="11px">
              <DashboardNavItem />
              <WidgetNavItem />
              <Spacing />
              <HelpMenuNavItem />
              <ManageAccountNavItem />
            </Stack>
          </StyledList>
        </Stack>
      </Box>
    </StyledDrawer>
  );
}
