import { Dialog, DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import { useState } from 'react';
import DeleteButton from './DeleteButton';
import { SettingsMenuProps } from './Settings';

const StyledWidgetIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  borderRadius: '3px',
  padding: '2px',
  '&:hover': {
    color: theme.palette.text.secondary,
    backgroundColor: `${theme.palette.action.hover} !important`,
  },
  '&:focus-visible': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const SettingsMenu = ({ id, widgetId, children }: SettingsMenuProps) => {
  const [isDialogOpen, toggleDialog] = useState(false);

  const onOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    toggleDialog(true);
  };

  const onClose = () => toggleDialog(false);

  return (
    <>
      {children && (
        <StyledWidgetIcon id={id} onClick={onOpenSettings} sx={{ fontSize: '18px' }}>
          <i className="ri-more-fill" />
        </StyledWidgetIcon>
      )}
      <DeleteButton id={widgetId} variant="link" />
      <Dialog id={`${id}-menu`} keepMounted open={isDialogOpen} onClose={onClose} fullWidth maxWidth="xl">
        <DialogTitle sx={{ margin: 0, padding: '2px 2px 0 0 ', textAlign: 'right' }}>
          <IconButton onClick={onClose}>
            <span className="ri-close-line" />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
};
