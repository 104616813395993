import { useAdminApi } from '@hooks/useAdminApi';
import { Box } from '@mui/material';
import { Alert } from '@protos/alerts';
import { toastifyService } from '@services/ToastifyService';
import { CellRendererActionButton } from '@shared/components/CellRendererActionButton';
import theme from '@shared/themes/darkTheme';
import { ICellRendererParams } from 'ag-grid-community';

export const ProductAlertsActionsCellRenderer = (props: ICellRendererParams<Alert>) => {
  const { apiClient } = useAdminApi();

  const onDismiss = async (data: Alert | undefined) => {
    if (data) {
      await apiClient?.deleteAlert(data?.id);
      toastifyService.showAlertDismissedConfirmation();
      if (props.api) props.api?.applyTransaction({ remove: [data] });
    }
  };

  return (
    <Box display="flex" flexDirection="row" gap={1} alignItems="center" justifyContent="center">
      <CellRendererActionButton
        label="Dismiss"
        onClick={event => onDismiss(props.data)}
        backgroundColor={theme.palette.error.dark}
        isDisabled={props.data?.dismissed}
      />
    </Box>
  );
};
