import { SeasonYearsToFetch } from './seasonalChartConsts';

export const getTailingContract = (letters: string, year: string) => {
  let result = '';
  letters.split('').forEach((letter, index) => {
    if (index !== 0) {
      result += `${letter}${year}`;
    }
  });
  return result;
};

const getTenorMonth = (letter: string): number | undefined => {
  switch (letter) {
    case 'F':
      return 0;
    case 'G':
      return 1;
    case 'H':
      return 2;
    case 'J':
      return 3;
    case 'K':
      return 4;
    case 'M':
      return 5;
    case 'N':
      return 6;
    case 'Q':
      return 7;
    case 'U':
      return 8;
    case 'V':
      return 9;
    case 'X':
      return 10;
    case 'Z':
      return 11;
    default:
      return undefined;
  }
};

export const generateAllHistoricSymbols = (product_symbol: string, tenor_code: string, tenor_year: string) => {
  const currentYear = Number(new Date().getFullYear().toLocaleString().slice(-2));
  const currentMonth = Number(new Date().getMonth());
  const tenorCodeYear = Number(tenor_year);
  const tenorCodeLetter = tenor_code.replace(/[^a-z]/gi, '');
  const tenorCodeMonth = getTenorMonth(tenorCodeLetter.toLocaleUpperCase());

  return Array.from({ length: SeasonYearsToFetch }).map((_, index) => {
    const isExpiredContract =
      currentYear === tenorCodeYear - index && tenorCodeMonth !== undefined ? currentMonth > tenorCodeMonth : currentYear > tenorCodeYear - index;
    const shiftFromCurrentYear = isExpiredContract ? index : 0;
    const seasonalContractSymbol =
      (!tenorCodeLetter.length && !tenor_code[3]) || tenorCodeLetter.length
        ? `${product_symbol}${tenorCodeLetter[0] ?? tenor_code[0]}${tenorCodeYear - index}${
            tenorCodeLetter.length > 1 ? getTailingContract(tenorCodeLetter, String(tenorCodeYear - index)) : ''
          }`
        : `${product_symbol}${tenor_code[0]}${Number(tenor_code[1] + tenor_code[2]) - index}${tenor_code[3]}${
            Number(tenor_code[4] + tenor_code[5]) - index
          }`;
    return { name: seasonalContractSymbol, shift: shiftFromCurrentYear };
  });
};
