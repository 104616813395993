import { PriceSettlementType } from '@features/dashboard/enums';
import { priceFormatterService } from '@services/PriceFormatterService';
import { Product, ProductTenor } from '@shared/protos/product';
import { SETTLEMENT_TYPE, SettlementPriceResponse, SettlementType } from '@shared/protos/settlementPrices';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { getBalDisplayName } from '../shared/utils';
import { SettlementPricesRow } from './types';

export const TENOR_COL_ID = 'tenorId';

export const tenorColumn: ColDef<SettlementPricesRow> = {
  field: 'tenorName',
  colId: TENOR_COL_ID,
  headerName: '',
  suppressSpanHeaderHeight: true,
  minWidth: 80,
  maxWidth: 120,
  lockPosition: 'left',
  pinned: 'left',
  cellClass: 'ag-tenor-column',
  resizable: true,
};

export const getColId = (productId: string, settlementType: SettlementType): string => `${productId}|${settlementType}`;

export const getSettlementType = (colId: string): SettlementType => colId.split('|')[1] as SettlementType;

export const mapSettlementTypeToHeaderName = (settlementType: SettlementType): string => {
  const headers = {
    [SettlementType.Sing]: '1630 SG',
    [SettlementType.Euro]: '1630 UK',
    [SettlementType.Flux]: '1930 UK',
  };

  return headers[settlementType] || '';
};

export const mapPriceSettlementTypeToSettlementType = (number: PriceSettlementType): SettlementType => {
  const types = {
    [PriceSettlementType.Flux]: SettlementType.Flux,
    [PriceSettlementType.Singapore]: SettlementType.Sing,
    [PriceSettlementType.European]: SettlementType.Euro,
  };

  return types[number];
};

export const mapSettlementColumns = (
  products: Product[],
  currentSettlements: SettlementType[],
  selectedColumns: string[],
  columnsOrder: string[]
): (ColDef<SettlementPricesRow, number> | ColGroupDef<SettlementPricesRow>)[] =>
  products
    .sort((a, b) => columnsOrder.indexOf(a.symbol) - columnsOrder.indexOf(b.symbol))
    .reduce<(ColDef<SettlementPricesRow, number> | ColGroupDef<SettlementPricesRow>)[]>(
      (accumulator, { symbol, full_name, short_name }, colIndex) => [
        ...accumulator,
        {
          headerName: short_name,
          groupId: symbol,
          marryChildren: true,
          suppressStickyLabel: true,
          headerTooltip: full_name,
          children: Object.keys(SettlementType).flatMap(settlementType => [
            {
              groupId: getColId(symbol, SettlementType[settlementType]),
              headerName: mapSettlementTypeToHeaderName(SettlementType[settlementType]),
              marryChildren: true,
              suppressStickyLabel: true,
              headerTooltip: getColId(symbol, SettlementType[settlementType]),
              children: [
                {
                  suppressSpanHeaderHeight: true,
                  colId: `${getColId(symbol, SettlementType[settlementType])}|price`,
                  headerName: '',
                  flex: 1,
                  minWidth: 60,
                  cellDataType: 'text',
                  cellClassRules: {
                    'ag-col-group-even': () => colIndex % 2 === 0,
                    'header-cell': () => true,
                  },
                  valueFormatter: ({ value }) => priceFormatterService.formatProductPrice(value),
                  valueGetter: ({ data }) => data?.[symbol]?.[settlementType.toLowerCase()].price,
                  hide: !currentSettlements.includes(SettlementType[settlementType]) || !selectedColumns.includes(symbol),
                },
                {
                  suppressSpanHeaderHeight: true,
                  colId: `${getColId(symbol, SettlementType[settlementType])}|delta`,
                  headerName: '',
                  flex: 1,
                  minWidth: 60,
                  cellDataType: 'text',
                  cellClassRules: {
                    'ag-col-group-even': () => colIndex % 2 === 0,
                    'positive-value': params => params.value > 0,
                    'negative-value': params => params.value < 0,
                  },
                  valueFormatter: ({ value }) => priceFormatterService.formatDeltaProductPrice(value),
                  valueGetter: ({ data }) => data?.[symbol]?.[settlementType.toLowerCase()].delta,
                  hide: !currentSettlements.includes(SettlementType[settlementType]) || !selectedColumns.includes(symbol),
                },
              ],
            },
          ]),
        },
      ],
      [tenorColumn]
    );

export const mapSettlementRows = (
  tenors: ProductTenor[],
  products: Product[],
  allSettlements: SettlementPriceResponse[],
  firstMonthIndex: number,
  firstWeekIndex: number
): SettlementPricesRow[] => {
  const SettlementsMap = new Map();

  allSettlements.forEach(({ symbol, value, snapshot_period, delta }) => {
    if (SettlementsMap.get(symbol)) {
      SettlementsMap.set(symbol, { ...SettlementsMap.get(symbol), [SETTLEMENT_TYPE[snapshot_period]]: { price: value ?? '', delta: delta ?? '' } });
    } else {
      SettlementsMap.set(symbol, { [SETTLEMENT_TYPE[snapshot_period]]: { price: value ?? '', delta: delta ?? '' } });
    }
  });

  return tenors.map(({ code, display, frequency, spread_code }, index) => {
    const newRow = {
      tenorId: code,
      tenorName: getBalDisplayName(firstMonthIndex, firstWeekIndex, index, display),
      tenorFrequency: frequency,
    };
    products.forEach(({ symbol, underlying_symbol, calendar_type, tenor_frequency }) => {
      const symbolData = SettlementsMap.get(`${symbol}${code}`) ?? SettlementsMap.get(`${symbol}${spread_code}`);
      if (symbolData) {
        let newSymbol = underlying_symbol;

        if (!underlying_symbol || (calendar_type === 'spread' && tenor_frequency !== 'quarterly')) {
          newSymbol = symbol;
        } else if (calendar_type === 'spread' && tenor_frequency === 'quarterly') {
          const tail = symbol.substring(underlying_symbol.length, symbol.length);
          const productName = underlying_symbol.substring(0, underlying_symbol.length - 1);
          newSymbol = `${productName}${tail}`;
        }

        newRow[newSymbol] = {
          productId: symbol,
          sing: { price: symbolData?.sing?.price, delta: symbolData?.sing?.delta },
          euro: { price: symbolData?.euro?.price, delta: symbolData?.euro?.delta },
          flux: { price: symbolData?.flux?.price, delta: symbolData?.flux?.delta },
        };
      }
    });

    return newRow as SettlementPricesRow;
  });
};
