import { useCallback, useMemo } from 'react';
import { useSearchParams as useSearchParamsReact } from 'react-router-dom';

export const useURLSearchParams = (): {
  urlParams: Record<string, any>;
  setURLSearchParam: (key: string, value: string | undefined) => Record<string, any>;
  resetURLSearchParams: (newParams: Record<string, any>) => Record<string, any>;
} => {
  const [searchParams, setSearchParams] = useSearchParamsReact();

  const setURLSearchParam = useCallback(
    (key: string, value: string | undefined) => {
      const currentQueryParameters = new URLSearchParams(searchParams.toString());

      if (value) {
        currentQueryParameters.set(key, value);
      } else {
        currentQueryParameters.delete(key);
      }
      setSearchParams(currentQueryParameters);
      return Object.fromEntries(currentQueryParameters);
    },
    [searchParams]
  );

  const resetURLSearchParams = useCallback((newParams: Record<string, any>) => {
    const newQueryParameters = new URLSearchParams();

    Object.keys(newParams).forEach(key => {
      if (newParams[key]) {
        newQueryParameters.set(key, newParams[key]);
      }
    });

    setSearchParams(newQueryParameters);
    return Object.fromEntries(newQueryParameters);
  }, []);

  return useMemo(
    () => ({
      urlParams: Object.fromEntries(searchParams),
      setURLSearchParam,
      resetURLSearchParams,
    }),
    [searchParams, setURLSearchParam, resetURLSearchParams]
  );
};
