import { FULLMO_TENOR_ID } from '@protos/grids';
import { streamDashboard } from '@services/context';
import { useStreamProduct } from '@shared/hooks/useStreamProduct';
import { Ticker, tickerMeta } from '@shared/protos/ticker';
import debounce from 'lodash/debounce';
import { ReactNode, createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { RollingRowSettings } from '../shared/types';
import { PricingGrid, usePricingGridState } from './usePricingGridState';

type Props = {
  children: ReactNode;
  widgetId: string;
  initialValues: {
    selectedColumns: string[];
    selectedRows: string[];
    columnsOrder: string[];
    userRollingRowSettings: RollingRowSettings;
    isOverrideRolling: boolean;
  };
};

const PricesContext = createContext<PricingGrid | undefined>(undefined);
PricesContext.displayName = 'PricesContext';

export default function PricesProvider({ widgetId, initialValues, ...otherProps }: Props) {
  const {
    selectedColumns,
    selectedRows,
    columnsOrder: initialColumnsOrder,
    userRollingRowSettings,
    isOverrideRolling: initialIsOverrideLoading,
  } = { ...initialValues };
  const gridState = usePricingGridState(selectedColumns, selectedRows, initialColumnsOrder, userRollingRowSettings, initialIsOverrideLoading);
  const { selectedColIds, selectedRowIds, columnsOrder, rollingRowSettings, isOverrideRolling, gridRef, productMap, tenorMap, selectedUserProducts } =
    gridState;
  const { editWidget } = streamDashboard;

  const debouncedEditWidget = useRef(
    debounce((selectedColIds, selectedRowIds, columnsOrder, rollingRowSettings, isOverrideRolling) => {
      editWidget(widgetId, {
        payload: {
          selectedColumns: selectedColIds,
          selectedRows: selectedRowIds,
          columnsOrder: columnsOrder,
          userRollingRowSettings: rollingRowSettings,
          isOverrideRolling,
        },
      });
    }, 1000)
  );

  useEffect(
    () => debouncedEditWidget.current(selectedColIds, selectedRowIds, columnsOrder, rollingRowSettings, isOverrideRolling),
    [selectedColIds, selectedRowIds, columnsOrder, rollingRowSettings, isOverrideRolling]
  );

  const tickerCallback = useMemo(() => {
    return (ticker: Ticker) => {
      const meta = tickerMeta(ticker, productMap, tenorMap);
      if (!meta || !gridRef.current) return;

      const gridApi = gridRef.current.getGridApi();
      const { tenor, productSymbolRoot } = meta;

      const rowNode = gridApi?.getRowNode(tenor.code);
      const rowFullPriceNode = gridApi?.getRowNode(FULLMO_TENOR_ID);
      const colId = gridApi?.getColumnDef(productSymbolRoot)?.colId;

      if (!rowNode || !colId) return;

      rowNode.setDataValue(colId, ticker.mid);
      if (rowFullPriceNode && ticker.full_price) rowFullPriceNode.setDataValue(colId, ticker.full_price);
    };
  }, [productMap, tenorMap, gridRef]);

  useStreamProduct(
    tickerCallback,
    selectedUserProducts.map(product => product.symbol)
  );

  const value = useMemo(() => ({ ...gridState }), [gridState]);

  return <PricesContext.Provider value={value} {...otherProps} />;
}

export function usePricesSettings(): PricingGrid {
  const context = useContext(PricesContext);

  if (!context) {
    throw new Error('usePricesSettings must be used within PricesProvider');
  }

  return context;
}
