import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ChartWidgetPayload, DataFeedPermissions, FloatingChartPayload } from '@protos/charts';
import { streamDashboard } from '@services/context';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useApi } from '@shared/hooks/useApi';
import { useCallback, useEffect, useRef } from 'react';
import { useFloatingChartState } from '../../../../../shared/contexts/FloatingChartContext';
import TradingViewChart from './TradingViewChart';
import chartDatafeed from './datafeed';

interface FloatingChartContainerProps {
  widgetId: string;
  chartSettings?: FloatingChartPayload;
}

export const StyledChartBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
}));

export const FloatingChartContainer = ({ widgetId, chartSettings }: FloatingChartContainerProps) => {
  const user = useUserContext();
  const { apiClient } = useApi();
  const { editWidget } = streamDashboard;
  const { userProducts, tenors } = useUserProductsAndTenorsContext();
  const { currentFloatingChartState, setCurrentFloatingChartState } = useFloatingChartState();

  const { chartState: streamUpdateFloatingChartState, symbol: streamUpdateSymbol } = chartSettings ?? { chartState: undefined, symbol: undefined };

  const chartContainerRef = useRef<HTMLDivElement>();

  const handleChartSettingChange = useCallback(<T extends keyof ChartWidgetPayload>(key: T, newValue: ChartWidgetPayload[T]) => {
    setCurrentFloatingChartState({ ...currentFloatingChartState, value: newValue, needsRefresh: false });

    if (newValue) {
      editWidget(widgetId, { payload: { [key]: newValue } });
    }
  }, []);

  useEffect(() => {
    if (currentFloatingChartState?.value !== streamUpdateFloatingChartState || streamUpdateSymbol) {
      setCurrentFloatingChartState({ value: streamUpdateFloatingChartState, needsRefresh: true, symbol: streamUpdateSymbol });
    }
  }, [streamUpdateFloatingChartState, streamUpdateSymbol]);

  useEffect(() => {
    if (!apiClient || (currentFloatingChartState && !currentFloatingChartState?.needsRefresh) || !user) return;

    const dataFeedPermissions: DataFeedPermissions = { cot_product_groups: user.cot_product_groups ?? [] };

    const datafeed = chartDatafeed(widgetId, apiClient, userProducts, tenors, dataFeedPermissions);
    const chart = new TradingViewChart({
      containerRef: chartContainerRef.current,
      chartState: currentFloatingChartState?.value,
      isFloatingChart: true,
      datafeed,
      permissions: dataFeedPermissions,
    });

    if (currentFloatingChartState?.symbol && !currentFloatingChartState.value) {
      chart.addSymbolToChart(currentFloatingChartState?.symbol);
      if (streamUpdateSymbol !== currentFloatingChartState?.symbol) editWidget(widgetId, { payload: { symbol: currentFloatingChartState?.symbol } });
    }

    chart.onAutoSaveNeeded(widgetId, newChartState => {
      handleChartSettingChange('chartState', newChartState);
    });
  }, [widgetId, apiClient, currentFloatingChartState, userProducts, tenors, user]);

  return <StyledChartBox ref={chartContainerRef} />;
};
