import { useAdminApi } from '@hooks/useAdminApi';
import { Box, Button, Stack, Typography } from '@mui/material';
import { toastifyService } from '@services/ToastifyService';
import { BlotterWidget } from '@shared/components/BotterWidget';
import { Dialog } from '@shared/components/Dialog';
import { Alert, AlertEvent } from '@shared/protos/alerts';
import { formatDatetimeWithMilliseconds } from '@utils/date';
import { ColDef } from 'ag-grid-community';
import * as React from 'react';
import { useState } from 'react';
import ReactJson from 'react-json-view';

const getRowId = ({ data }): string => data?.id?.toString();

const defaultColDef = {
  sortable: true,
  filter: false,
  floatingFilter: false,
  resizable: true,
};

const isValidJSONString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const EventsAlertsDialog: React.FC<{ selectedEventsAlert: Alert; onClose: () => void }> = ({ selectedEventsAlert, onClose }) => {
  const { apiClient } = useAdminApi();
  const [eventMessageToViz, setEventMessageToViz] = useState<object | undefined>();
  const EventsAlertsDialogColumns: ColDef<AlertEvent>[] = [
    {
      field: 'id',
      headerName: 'S.No.',
      resizable: true,
      headerTooltip: 'This is just a serial number in this grid',
      valueGetter: params => (params.node?.rowIndex ?? 0) + 1,
      maxWidth: 50,
    },
    {
      field: 'message',
      headerName: ' Message',
      resizable: true,
      flex: 1,
      headerTooltip: 'Event Message',
      onCellDoubleClicked: ({ data }) => {
        if (data) {
          const { message } = data;
          const isJSONMessage = isValidJSONString(message);

          setEventMessageToViz(isJSONMessage ? JSON.parse(message) : { text: message });
        }
      },
    },
    {
      field: 'timestamp',
      headerName: ' Timestamp',
      valueFormatter: ({ value }) => formatDatetimeWithMilliseconds(value),
      resizable: true,
      headerTooltip: 'Event Timestamp',
    },
  ];

  const customCopyHandler = async (copy: { src: any }) => {
    const textToCopy = JSON.stringify(copy.src, null, 2);
    try {
      await navigator.clipboard.writeText(textToCopy);
      toastifyService.showSuccess('Copied to clipboard successfully!');
    } catch (err) {
      toastifyService.showError('Failed to copy to clipboard!');
    }
  };

  if (!apiClient) return null;

  const eventsAlertsDataLoader = apiClient.alertEventsLoader(selectedEventsAlert.id);

  return (
    <Dialog
      fullWidth
      open={!!selectedEventsAlert}
      onClose={onClose}
      showClose={true}
      title="Alert events"
      {...(eventMessageToViz ? { maxWidth: 'xl' } : {})}
    >
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', gap: 1 }}>
        <BlotterWidget dataLoader={eventsAlertsDataLoader} columnDefs={EventsAlertsDialogColumns} getRowId={getRowId} defaultColDef={defaultColDef} />
        {eventMessageToViz && (
          <Stack direction="column" spacing={1} sx={{ flex: 1, overflow: 'auto' }}>
            <Typography fontSize={13}>Alert Event Message Viewer</Typography>
            <ReactJson
              src={eventMessageToViz}
              theme="monokai"
              style={{ minWidth: '40%', padding: 10, maxHeight: 530, overflow: 'auto' }}
              name="message"
              enableClipboard={customCopyHandler}
            />
            <Button size="small" style={{ fontSize: 11, alignSelf: 'end' }} color="info" onClick={() => setEventMessageToViz(undefined)}>
              Close Viewer
            </Button>
          </Stack>
        )}
      </Box>
    </Dialog>
  );
};
