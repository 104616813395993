import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material';
import { useState } from 'react';

interface SelectProps {
  label: string;
  options: string[];
  value: string | undefined;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
}

export const Select = ({ label, options, value, onChange, style }: SelectProps) => {
  const [filtervalue, setFilterValue] = useState<string | undefined>(value);

  const onSelectChange = event => {
    setFilterValue(event.target.value as string);
    onChange(event.target.value as string);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120, fontSize: 11, ...style }} size="small">
      <InputLabel id={`select-filter-${label}`} sx={{ fontSize: 11, marginLeft: -0.3 }}>
        {label}
      </InputLabel>
      <MuiSelect
        labelId={`select-filter-${label}`}
        value={filtervalue}
        label={label}
        onChange={onSelectChange}
        sx={{
          '& .MuiInputBase-input': {
            fontSize: 11,
          },
        }}
      >
        {options.map(option => (
          <MenuItem key={option} value={option} style={{ fontSize: 11 }}>
            {option}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};
