import { Box, Divider, Typography } from '@mui/material';

interface SettingsFormResultLayoutProps {
  title: string;
  Form?: React.ReactNode;
  Result?: React.ReactNode;
}

export const SettingsFormResultLayout = ({ title, Form, Result }: SettingsFormResultLayoutProps) => {
  return (
    <Box display="flex" flex={1.5} flexDirection="column" gap={2} overflow="hidden">
      <Typography variant="h4">{title}</Typography>
      {Form && <Box textAlign="center">{Form}</Box>}
      {Result && (
        <>
          <Divider />
          <Box textAlign="center">{Result}</Box>
        </>
      )}
    </Box>
  );
};
