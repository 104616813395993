import { Filter, FilterType } from '@protos/filter';
import { formatDateTimeUTC } from '@utils/date';
import { precisionFormatter } from '@utils/number';
import { ColDef } from 'ag-grid-community';

export enum OutlierStatus {
  OPEN = 'open',
  DISMISSED = 'dismissed',
  APPLIED = 'applied',
  QUEUED = 'queued',
}

export type Outlier = {
  id: number;
  symbol: string;
  start: string;
  end: string;
  period: string;
  status: OutlierStatus;
  average_score: string;
  max_score: string;
};

enum Period {
  S = '1s',
  M = '1m',
  H = '1h',
  D = '1d',
}

export const PERIOD_OPTIONS: Period[] = [Period.S, Period.M, Period.H, Period.D];
export const STATUS_OPTIONS: OutlierStatus[] = [OutlierStatus.OPEN, OutlierStatus.DISMISSED, OutlierStatus.APPLIED, OutlierStatus.QUEUED];

export const OUTLIER_COLUMNS: ColDef<Outlier>[] = [
  {
    field: 'id',
    headerName: 'ID',
    resizable: true,
    headerTooltip: 'ID',
  },
  {
    field: 'symbol',
    headerName: 'Symbol',
    headerTooltip: 'Symbol',
    flex: 2,
  },
  {
    field: 'start',
    headerName: 'Start',
    valueFormatter: ({ value }) => formatDateTimeUTC(value),
    resizable: true,
    flex: 2,
    headerTooltip: 'Start',
    sort: 'asc',
  },
  {
    field: 'end',
    headerName: 'End',
    valueFormatter: ({ value }) => formatDateTimeUTC(value),
    resizable: true,
    flex: 2,
    headerTooltip: 'End',
  },
  {
    field: 'period',
    headerName: 'Period',
    headerTooltip: 'Period',
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    headerTooltip: 'Status',
    flex: 1,
  },
  {
    field: 'average_score',
    headerName: 'Avg. Score',
    headerTooltip: 'Average Score',
    valueFormatter: ({ value }) => precisionFormatter(value),
    flex: 1,
  },
  {
    field: 'max_score',
    headerName: 'Max Score',
    headerTooltip: 'Maximum Score',
    valueFormatter: ({ value }) => precisionFormatter(value),
    flex: 1,
  },
];

export const OUTLIER_FILTERS: Filter[] = [
  { type: FilterType.SELECT, name: 'status', label: 'Status', options: STATUS_OPTIONS, defaultValue: OutlierStatus.OPEN },
];
