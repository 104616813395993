import { Grid } from '@features/dashboard/widgets/grid';
import { Box, BoxProps, Typography, alpha, styled } from '@mui/material';
import { ColDef, ColGroupDef, DomLayoutType, RowClickedEvent } from 'ag-grid-community';

interface BlotterCardProps<T> {
  title: string;
  data: T[];
  columns: (ColDef<T, any> | ColGroupDef<T>)[];
  colDefOptions?: ColDef<T, any>;
  fullWidth?: boolean;
  domLayout?: DomLayoutType;
  onRowClicked?: (event: RowClickedEvent<T, any>) => void;
}

interface StyledBoxProps extends BoxProps {
  fullWidth?: boolean;
  autoHeight?: boolean;
}

const defaultColDef = {
  sortable: true,
  filter: false,
  floatingFilter: false,
  resizable: true,
};

const StyledRoundedBox = styled((props: StyledBoxProps) => <Box {...props} />, {
  shouldForwardProp: (propName: string) => propName !== 'fullWidth' && propName !== 'autoHeight',
})(({ theme, fullWidth, autoHeight }) => ({
  padding: '10px',
  overflow: 'hidden',
  outline: `1px solid ${alpha(theme.palette.border, 0.1)}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minHeight: autoHeight ? 'none' : 400,
  minWidth: 400,
  ...(fullWidth
    ? {
        width: '100%',
      }
    : { maxWidth: 600 }),
}));

export const BlotterCard = <T,>({ title, data, columns, colDefOptions, fullWidth, domLayout, onRowClicked }: BlotterCardProps<T>) => {
  return (
    <StyledRoundedBox fullWidth={fullWidth} autoHeight={domLayout === 'autoHeight'}>
      <Typography variant="h6" gutterBottom sx={{ marginBottom: '10px' }}>
        {title}
      </Typography>
      <Grid<T>
        rowData={data}
        defaultColDef={{ ...defaultColDef, ...colDefOptions }}
        enableCellTextSelection
        columnDefs={columns}
        domLayout={domLayout}
        onRowClicked={onRowClicked}
      />
    </StyledRoundedBox>
  );
};
