import { streamRFQ } from '@services/context';
import { TickerCallback } from '@services/streamRFQService';
import { useEffect, useRef } from 'react';

// hook for subscribing to a rfqSymbol (`symbol-size`)
export const useStreamRFQ = (callback: TickerCallback, rfqSymbolToSubscribe: string, selectedExchange: string) => {
  const target = useRef<TickerCallback>(callback);

  useEffect(() => {
    if (!rfqSymbolToSubscribe) return;
    target.current = callback;

    streamRFQ.subscribe(rfqSymbolToSubscribe, selectedExchange, callback);

    return () => {
      streamRFQ.unsubscribe(rfqSymbolToSubscribe, selectedExchange, callback);
    };
  }, [rfqSymbolToSubscribe, selectedExchange]);
};
