import { useAdminApi } from '@hooks/useAdminApi';
import { Box } from '@mui/material';
import { toastifyService } from '@services/ToastifyService';
import { CellRendererActionButton } from '@shared/components/CellRendererActionButton';
import theme from '@shared/themes/darkTheme';
import { ICellRendererParams } from 'ag-grid-community';
import { Outlier, OutlierStatus } from './outliersProtos';

export const OutliersActionsCellRenderer = (props: ICellRendererParams<Outlier>) => {
  const { apiClient } = useAdminApi();

  const onApply = async (data: Outlier | undefined) => {
    if (data) {
      const updatedOutlier = await apiClient?.updateOutlier(data?.id, { status: OutlierStatus.APPLIED });
      if (!updatedOutlier) {
        props.api?.applyTransaction({ update: [{ ...data }] });
        toastifyService.showErrorMessage('Failed to apply outlier');
        return;
      }
      props.api?.applyTransaction({ update: [{ ...data, status: updatedOutlier.status }] });
    }
  };

  const onDismiss = async (data: Outlier | undefined) => {
    if (data) {
      const updatedOutlier = await apiClient?.updateOutlier(data?.id, { status: OutlierStatus.DISMISSED });
      if (!updatedOutlier) {
        props.api?.applyTransaction({ update: [{ ...data }] });
        toastifyService.showErrorMessage('Failed to dismiss outlier');
        return;
      }
      props.api?.applyTransaction({ update: [{ ...data, status: updatedOutlier.status }] });
    }
  };

  return props.data?.status === OutlierStatus.OPEN ? (
    <Box display="flex" flexDirection="row" gap={1} alignItems="center" justifyContent="center">
      <CellRendererActionButton
        label="Dismiss"
        onClick={() => onDismiss(props.data)}
        backgroundColor="white"
        textColor={theme.palette.background.default}
        isDisabled={props.data?.status !== OutlierStatus.OPEN}
      />
      <CellRendererActionButton
        label="Apply"
        onClick={() => onApply(props.data)}
        backgroundColor={theme.palette.error.dark}
        isDisabled={props.data?.status !== OutlierStatus.OPEN}
      />
    </Box>
  ) : null;
};
