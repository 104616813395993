import { FormControl, InputAdornment, InputLabel, OutlinedInput, styled } from '@mui/material';
import { IconComponent } from '@shared/components/IconComponent';
import { useEffect, useState } from 'react';

type Props = {
  value: string;
  hideLabel?: boolean;
  miniSearch?: boolean;
  maxWidth?: string;
  onSearch: (text: string) => void;
};

const StyledAdornment = styled(InputAdornment)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const Search: React.FC<Props> = ({ value, onSearch, hideLabel, miniSearch, maxWidth }) => {
  const [searchValue, setSearchValue] = useState(value);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  useEffect(() => {
    onSearch(searchValue);
  }, [searchValue]);

  return (
    <FormControl sx={{ maxWidth: maxWidth ?? '250px', width: '100%', alignSelf: 'center' }}>
      {!hideLabel && <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>}
      <OutlinedInput
        value={searchValue}
        id="outlined-adornment-search"
        {...(!hideLabel && { label: 'Search' })}
        startAdornment={
          <StyledAdornment position="start">
            <IconComponent name="ri-search-line" iconStyle={{ fontSize: 11 }} />
          </StyledAdornment>
        }
        size="small"
        onChange={e => {
          const data = e.target.value;
          setSearchValue(data);
        }}
        inputProps={{ style: { fontSize: 11 } }}
        {...(miniSearch && { style: { height: '20px', borderRadius: '0px' } })}
      />
    </FormControl>
  );
};
