import { ICellRendererParams } from 'ag-grid-community';

export const TradingGridCellRenderer = (params: ICellRendererParams) => {
  const colId = params.colDef?.colId ?? '';
  const isBidBtn = colId?.includes('bidBtn');
  const rowIndex = params.node.rowIndex;

  const isTradableCell = (params.data && !!params.data[colId.split('-')[0]].mid) || (rowIndex !== 0 && rowIndex !== 1);

  if (!isTradableCell) {
    return null;
  }

  return (
    <button
      style={{
        backgroundColor: isBidBtn ? '#4189E8' : '#CD4B4B',
        border: 'none',
        textAlign: 'center',
        display: 'inline-block',
        borderRadius: 5,
        color: 'white',
        cursor: 'pointer',
        minHeight: 15,
        width: 35,
        fontSize: 11,
      }}
      data-testid={isBidBtn ? `buy-button` : `sell-button`}
    >
      {isBidBtn ? 'Buy' : 'Sell'}
    </button>
  );
};
