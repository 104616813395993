import { useScreenSize } from '@shared/hooks/useScreenSize';
import { ReactElement } from 'react';
import DeleteButton from './DeleteButton';
import { SettingsMenu } from './SettingsMenu';

export type SettingsMenuProps = {
  id: string;
  title?: string;
  children?: ReactElement;
  widgetId: string;
};

export default function Settings(props: SettingsMenuProps) {
  const { isMobile } = useScreenSize();

  return !isMobile ? <SettingsMenu {...props} /> : <DeleteButton id={props.widgetId} variant="link" />;
}
