import { streamOrders } from '@services/context';
import { OrderCallback } from '@services/streamOrderService';
import { useEffect } from 'react';

export const useStreamOrders = (callback: OrderCallback) => {
  useEffect(() => {
    streamOrders.subscribe(callback);

    return () => {
      streamOrders.unsubscribe();
    };
  }, []);
};
