import { WidgetType } from '@features/dashboard/enums';
import { Widget } from '@features/dashboard/types';
import { FULLMO_TENOR_ID } from '@protos/grids';
import { streamDashboard } from '@services/context';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { getContractForSymbolInfo } from '@utils/symbol';
import { ColDef, GetRowIdParams, IRowNode, ITooltipParams, RowClassParams } from 'ag-grid-community';
import { useCallback, useEffect, useMemo } from 'react';
import { useFloatingChartState } from '../../../../../shared/contexts/FloatingChartContext';
import { Grid } from '../../grid';
import { TENOR_COL_ID } from '../../settlement-prices/utils';
import { usePricesSettings } from '../PricesContext';
import { FULLMO_TENOR_ROW, PricesRow } from '../types';
import { PicesAnalyticsTooltip } from './PricesAnalyticsTooltip';

function getRowId({ data: { tenorId } }: GetRowIdParams<PricesRow>): string {
  return tenorId;
}

const isJuneDecRow = (params: RowClassParams<PricesRow, any>) =>
  params.data?.tenorFrequency === 'monthly' && (params.data.tenorName.includes('JUN') || params.data.tenorName.includes('DEC'));

export default function PricesGrid() {
  const user = useUserContext();
  const { columnDefs, columns, rows, filteredSelectedTenors, onColumnMoved, gridRef, productMap, tenorMap } = usePricesSettings();
  const { addWidget, getCurrentDashboard } = streamDashboard;
  const { setCurrentFloatingChartState } = useFloatingChartState();

  const priceColumns = useMemo(
    () =>
      columns?.reduce((acc, col: ColDef<PricesRow, any>) => {
        if (col.colId !== TENOR_COL_ID) {
          acc.push({
            ...col,
            ...(col.colId && user?.cot_product_groups.includes(productMap[col.colId]?.product_group)
              ? {
                  tooltipValueGetter: (p: ITooltipParams) => p.value,
                  tooltipComponent: PicesAnalyticsTooltip,
                  tooltipComponentParams: { columnId: col.colId, productMap, tenorMap },
                }
              : {}),
          });
        } else {
          acc.push(col);
        }
        return acc;
      }, [] as ColDef<PricesRow, any>[]),
    [columns, user]
  );

  const FullMoRow = useMemo(() => {
    if (productMap) {
      const fullMoRow = { ...FULLMO_TENOR_ROW };
      Object.keys(productMap).forEach(val => {
        fullMoRow[val] = '';
      });

      return fullMoRow;
    }

    return undefined;
  }, [productMap]);

  const firstFrequencyRows = useMemo(() => {
    return filteredSelectedTenors.reduce((acc, tenor, index) => {
      if (index === 0) {
        const nextTenor = filteredSelectedTenors[index + 1];
        if (nextTenor.frequency !== tenor.frequency) {
          acc.push(tenor.code);
        }
      } else {
        const prevTenor = filteredSelectedTenors[index - 1];
        if (prevTenor.frequency !== tenor.frequency) {
          acc.push(tenor.code);
        }
      }

      return acc;
    }, [] as string[]);
  }, [filteredSelectedTenors]);

  const getAllRows = useCallback(() => {
    if (rows && FullMoRow) {
      const BalMoIndex = rows.findIndex(row => row.tenorName.includes('BALMO'));
      if (BalMoIndex === -1) return rows;

      const allRows = [...rows];
      allRows.splice(BalMoIndex, 0, FullMoRow);
      return allRows;
    }

    return rows ?? [];
  }, [rows, FullMoRow]);

  const isFullMoRowPresentInBlotter = useMemo(() => {
    const allRows = getAllRows();
    if (FullMoRow) {
      return allRows?.some(row => row.tenorName.includes('FULLMO'));
    }

    return false;
  }, [getAllRows, FullMoRow]);

  const isFirstFrequencyRow = useCallback(
    (params: RowClassParams<PricesRow, any>) => {
      if (params.rowIndex === 0) return false;
      if (params.data?.tenorId === FULLMO_TENOR_ID) return true;

      if (isFullMoRowPresentInBlotter) {
        if (params.data?.tenorId && firstFrequencyRows.includes(params.data?.tenorId) && params.data?.tenorFrequency !== 'monthly') return true;
        return false;
      } else {
        if (params.data?.tenorId && firstFrequencyRows.includes(params.data?.tenorId)) return true;
        return false;
      }
    },
    [firstFrequencyRows, isFullMoRowPresentInBlotter]
  );

  const getRowStyle = useCallback(
    (params: RowClassParams<PricesRow, any>) => {
      if (isFirstFrequencyRow(params)) {
        return { borderBottom: 'none', borderTop: '1px solid #f1c40f', ...(isJuneDecRow(params) ? { borderBottom: '1px solid grey' } : {}) };
      }

      if (isJuneDecRow(params)) {
        return { borderBottom: '1px solid grey', borderTop: '1px solid grey' };
      }

      return undefined;
    },
    [isFirstFrequencyRow, isJuneDecRow]
  );

  const selectedRowIds = useMemo(() => filteredSelectedTenors.map(elem => elem.code), [filteredSelectedTenors]);

  const isExternalFilterPresent = useCallback(() => selectedRowIds.length > 0, [selectedRowIds]);
  const doesExternalFilterPass = useCallback(
    (rowNode: IRowNode<PricesRow>) => (rowNode.data ? [...selectedRowIds, FULLMO_TENOR_ID].includes(rowNode.data.tenorId) : true),
    [selectedRowIds]
  );

  const onGridCellClicked = useCallback(
    (params: any) => {
      // Check if a cell with a price is clicked
      if (params.value && productMap && tenorMap) {
        const { data, colDef } = params;
        const contractSymbol = getContractForSymbolInfo(colDef.colId, data, productMap, tenorMap);

        // Open floating chart widget
        if (contractSymbol) {
          const currentDashboard = getCurrentDashboard();
          const existingFloatingWidget = currentDashboard?.json?.widgets?.find((widget: Widget) => widget.type === WidgetType.FloatingChart);
          if (existingFloatingWidget) {
            setCurrentFloatingChartState({ value: undefined, needsRefresh: true, symbol: contractSymbol });
          } else {
            addWidget({ widgetType: WidgetType.FloatingChart, payload: { symbol: contractSymbol, chartState: undefined } });
          }
        }
      }
    },
    [productMap, tenorMap, setCurrentFloatingChartState, addWidget, getCurrentDashboard]
  );

  useEffect(() => {
    // After filters have been changed via API, we must ensure that the grid is refreshed;
    // If ´api.onFilterChanged()´ is not called, the grid will not be refreshed.
    if (gridRef?.current) {
      gridRef.current.getGridApi()?.onFilterChanged();
    }
  }, [selectedRowIds]);

  return (
    <Grid<PricesRow>
      ref={gridRef}
      defaultColDef={columnDefs}
      columnDefs={priceColumns}
      rowData={getAllRows()}
      getRowId={getRowId}
      isExternalFilterPresent={isExternalFilterPresent}
      doesExternalFilterPass={doesExternalFilterPass}
      onColumnMoved={onColumnMoved}
      suppressRowVirtualisation
      suppressColumnVirtualisation
      getRowStyle={getRowStyle}
      onCellClicked={onGridCellClicked}
      tooltipShowDelay={1000}
      tooltipInteraction={true}
      suppressChangeDetection
      autoSizeStrategy={undefined}
      domLayout="autoHeight"
    />
  );
}
