import { Theme } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body: true;
  }
}

export function themeFontSizes(theme: Theme): Theme {
  theme.typography.h1 = {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 60,
    lineHeight: '81.72px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      lineHeight: '40.86px',
    },
    fontFamily: 'Noto Sans',
  };
  theme.typography.h2 = {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.header,
    fontSize: 40,
    lineHeight: '54.48px',
    marginTop: '16px',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
      lineHeight: '34.05px',
    },
    fontFamily: 'Noto Sans',
  };
  theme.typography.h3 = {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 30,
    lineHeight: '40.86px',
    marginBottom: '3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
      lineHeight: '23.15px',
    },
    fontFamily: 'Noto Sans',
  };
  theme.typography.h4 = {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 22,
    lineHeight: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      lineHeight: '27px',
    },
    fontFamily: 'Noto Sans',
  };
  theme.typography.h5 = {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 18,
    lineHeight: '24.52px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '21.79px',
    },
    fontFamily: 'Noto Sans',
  };
  theme.typography.h6 = {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    lineHeight: '19.07px',
    fontFamily: 'Noto Sans',
  };
  theme.typography.body = {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 11,
    lineHeight: '15px',
    fontFamily: 'Noto Sans',
  };
  theme.typography.body1 = {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 14,
    lineHeight: '19px',
    fontFamily: 'Noto Sans',
  };
  theme.typography.body2 = {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 18,
    lineHeight: '24.52px',
    fontFamily: 'Noto Sans',
  };
  theme.typography.subtitle1 = {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 22,
    lineHeight: '29.96px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
      lineHeight: '23.15px',
    },
    fontFamily: 'Noto Sans',
  };
  theme.typography.caption = {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#999999',
    fontSize: 14,
    lineHeight: '19.07px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      lineHeight: '13.62px',
    },
    fontFamily: 'Noto Sans',
  };
  return theme;
}
