import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { config } from '@services/context';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LogoImg from './onyxlogo.svg';

const StyledLogo = styled(Link)({
  '&:hover': {
    opacity: 0.8,
  },
  '& > img': {
    width: 20,
  },
}) as typeof Link;

const StyledAppName = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: 10,
  lineHeight: '14px',
  textAlign: 'center',
  letterSpacing: '0.14em',
  color: '#FFFFF2',
  height: '16px',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
  width: '18px',
}));

export default function Logo() {
  const navigate = useNavigate();

  return (
    <Stack alignItems="center" spacing="6px" mt="13px" mb="13px">
      <StyledLogo id="home-logo-lnk" component={RouterLink} to="/">
        <img src={LogoImg} alt="Flux logo" />
      </StyledLogo>
      <StyledDivider />
      <StyledAppName onDoubleClick={() => navigate(`/hall-of-fame`)}>FLUX{config.isDev ? ` DEV` : ''}</StyledAppName>
    </Stack>
  );
}
