export const transformParams = (params: any, schema: Record<string, any>) => {
  const { properties } = params;
  if (!properties) return params;

  const transformedParams = Object.entries(properties).reduce((acc, [key, value]) => {
    const { allOf, ...restValue } = value as Record<string, any>;
    if (value && allOf && allOf.length) {
      const newValue = allOf.reduce((acc, item) => {
        const schemaProperty = item.$ref.split('/').pop();
        const schemaValue = schema[schemaProperty];
        if (schemaValue && schemaValue.enum) acc.push(...schemaValue.enum);
        return acc;
      }, []);

      return { ...acc, [key]: { ...restValue, enum: newValue } };
    }

    return { ...acc, [key]: value };
  }, {});

  return { ...params, properties: transformedParams, required: Object.keys(properties) };
};
