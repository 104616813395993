import { styled } from '@mui/material';
import { streamDashboard } from '@services/context';
import { ActionButton } from '@shared/components/Button';

type SaveButtonProps = {
  inputValue: string;
  currentDashboardName: string;
};

type ButtonState = {
  text: string;
  disabled: boolean;
};

const StyledActionButton = styled(ActionButton)(({ theme }) => ({
  height: '34px',
  '& span': {
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 25;

function useSaveButton(inputValue: string, dashboardName: string): ButtonState {
  const trimInputValue = inputValue.trim();
  const inputLength = trimInputValue.length;
  const isTouched = trimInputValue !== dashboardName;
  const { dashboardList } = streamDashboard;
  const dashboards = dashboardList();
  const nameExists = dashboards.findIndex(d => d.name === trimInputValue) > -1;

  if (isTouched && nameExists) {
    return {
      text: 'Save (name taken)',
      disabled: true,
    };
  }

  if (inputLength > 0 && inputLength < NAME_MIN_LENGTH) {
    const diff = NAME_MIN_LENGTH - inputLength;
    const predicate = diff === 1 ? 'character' : 'characters';

    return {
      text: `Save (add ${diff} ${predicate})`,
      disabled: true,
    };
  }

  return { text: 'Save', disabled: !isTouched || inputLength === 0 };
}

export default function EditDashboardSaveButton({ inputValue, currentDashboardName }: SaveButtonProps) {
  const { text, disabled } = useSaveButton(inputValue, currentDashboardName);

  return <StyledActionButton type="submit" text={text} disabled={disabled} />;
}
