import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { useStream } from '@shared/hooks/useStream';
import { ReactNode, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { StyledScopedCssBaseline } from '@features/base/ScopedCssBaseline';
import AppFrame from '@features/base/private/AppFrame';
import PageLoadProgress from '@shared/components/PageLoadProgress';
import darkTheme from '@shared/themes/darkTheme';

import FloatingChartProvider from '@shared/contexts/FloatingChartContext';
import TradingTenorProvider from '@shared/contexts/TradingTenorProvider';
import UserContextProvider from '@shared/contexts/UserContextProvider';
import UserProductsAndTenorsContextProvider from '@shared/contexts/UserProductsAndTenorsProvider';
import 'src/shared/styles/utils/fonts.css';

type PrivatePageContainerProps = {
  children: ReactNode;
};

function PrivatePageContainer({ children }: PrivatePageContainerProps) {
  return (
    <div id="flux-container">
      <ThemeProvider theme={darkTheme}>
        <StyledScopedCssBaseline enableColorScheme>
          <AppFrame>{children}</AppFrame>
        </StyledScopedCssBaseline>
      </ThemeProvider>
    </div>
  );
}

export default function PrivatePage() {
  useStream();

  return (
    <UserContextProvider>
      <UserProductsAndTenorsContextProvider>
        <FloatingChartProvider>
          <TradingTenorProvider>
            <PrivatePageContainer>
              <Suspense fallback={<PageLoadProgress />}>
                <Outlet />
              </Suspense>
            </PrivatePageContainer>
          </TradingTenorProvider>
        </FloatingChartProvider>
      </UserProductsAndTenorsContextProvider>
    </UserContextProvider>
  );
}
