import { usePageTitle } from '@hooks/usePageTitle';
import { BlotterWidget } from '@shared/components/BotterWidget';
import { useAdminApi } from '@shared/hooks/useAdminApi';
import {
  SETTLEMENT_PRODUCT_COLUMNS,
  SETTLEMENT_PRODUCT_FILTERS,
  SettlementProduct,
  SettlementProductResponse,
} from '@shared/protos/settlementProducts';
import theme from '@shared/themes/darkTheme';
import { ColDef, RowClassParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReactProps } from 'ag-grid-react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const getRowId = ({ data }): string => data.product_symbol;

const defaultColDef = (navigate: NavigateFunction) => {
  return {
    sortable: true,
    filter: false,
    floatingFilter: false,
    resizable: true,
    flex: 1,
    onCellClicked(event) {
      if (event.column.getColId() === 'product_symbol') {
        navigate(`/admin/products/${event.data.product_symbol}`);
      }
    },
    cellStyle(params) {
      if (params.column.getColId() === 'product_symbol') {
        return { cursor: 'pointer' };
      }
      return null;
    },
  } as ColDef;
};

const dataTransformer = (data?: SettlementProductResponse[]) => {
  const newData = [] as SettlementProduct[];

  if (!data) return newData;

  data.forEach((settlement: SettlementProductResponse) => {
    if (settlement.prices.length > 0) {
      settlement.prices.forEach(item => {
        newData.push({ ...item, product_symbol: settlement.product_symbol });
      });
    } else {
      newData.push({ product_symbol: settlement.product_symbol, date: '', price: '', source: '', code: '', last_modified: '' });
    }
  });

  return newData as SettlementProduct[];
};

const getRowStyle = (params: RowClassParams<SettlementProduct, any>) => {
  if (!params.data?.price) {
    return { backgroundColor: theme.palette.background.infoRow };
  }

  return undefined;
};

export const SettlementsPage = ({ ...gridProps }: AgGridReactProps<SettlementProduct>) => {
  usePageTitle('Settlements');
  const { apiClient } = useAdminApi();
  const navigate = useNavigate();
  const dataLoader = apiClient?.settlementsLoader();

  if (!dataLoader) return;

  return (
    <BlotterWidget
      dataLoader={dataLoader}
      dataTransformer={dataTransformer}
      columnDefs={SETTLEMENT_PRODUCT_COLUMNS}
      filters={SETTLEMENT_PRODUCT_FILTERS}
      getRowId={getRowId}
      defaultColDef={defaultColDef(navigate)}
      enableCellTextSelection
      autoSizeStrategy={{ type: 'fitGridWidth' }}
      getRowStyle={getRowStyle}
    />
  );
};
