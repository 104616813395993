import { AppLoggerService } from './AppLoggerService';
import AppConfig from './Config';
import { Stream, StreamEvent, Subscription } from './Stream';
import StringService from './StringService';
import { streamDashboardService } from './streamDashboardService';
import { streamOrderService } from './streamOrderService';
import { streamProductRiskService } from './streamProductRiskService';
import { streamProductService } from './streamProductService';
import { streamRFQService } from './streamRFQService';
import { streamSnapshotsService } from './streamSnapshotsService';

export const config = AppConfig.fromDocument();
export const logger = new AppLoggerService(config);
export const stringService = new StringService();
export const stream = new Stream(config.wsUrl);
export const streamProduct = streamProductService(stream);
export const streamDashboard = streamDashboardService(stream, config, logger);
export const streamRFQ = streamRFQService(stream);
export const streamProductRisk = streamProductRiskService(stream);
export const streamOrders = streamOrderService(stream);
export const streamSnapshots = streamSnapshotsService(stream);

// when stream connect subscribe to server info
stream.onConnect(() => {
  stream.subscribe(Subscription.serverInfo());
});
// fetch websocket uid from server info message
stream.onEvent('server_info', (event: StreamEvent) => {
  stream.uid = event.message.uid;
});
