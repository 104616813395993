import { stream, streamDashboard } from '@services/context';
import { Dashboard } from '@shared/protos/dashboard';
import { useEffect, useState } from 'react';

export const useDashboard = (dashboardId?: string | number) => {
  const [lastModified, setLastModified] = useState({});
  const [currentDashboard, setCurrentDashboard] = useState<Dashboard>();

  const { isLoading, getDashboard, getCurrentDashboard } = streamDashboard;
  const loading = isLoading();

  useEffect(() => {
    const unsubscribe = stream.onEvent('dashboards', streamEvent => {
      if (!dashboardId) setLastModified({});
      else {
        const dashboard = getDashboard(dashboardId);
        const currentDashboard = getCurrentDashboard();
        let refresh = true;

        if (dashboard) {
          if (dashboard.websocket_uid === stream.uid && streamEvent.messageType === 'update') {
            refresh = false;
          } else {
            setLastModified(dashboard.last_modified);
          }
        } else {
          setLastModified({});
        }

        if (refresh) {
          setCurrentDashboard(currentDashboard);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [loading, dashboardId]);

  return {
    loading,
    lastModified,
    currentDashboard,
  };
};
