export type QueryType = Record<string, any>;

export const localUrl = (pathOrUrl: string) =>
  process.env.NODE_ENV === 'test' ? new URL('http://localhost:8080') : new URL(pathOrUrl, location.href);

export const localWsUrl = (pathOrUrl: string) => {
  const url = localUrl(pathOrUrl);
  if (url.protocol === 'https:') url.protocol = 'wss:';
  else if (url.protocol === 'http:') url.protocol = 'ws:';
  return url;
};

export const urlQuery = (url: string, query: QueryType): string => {
  if (query) {
    const w = getWindow();
    const u = w ? new URL(url, window.location.origin) : new URL(url);
    Object.keys(query).forEach(key => {
      let values = query[key];
      if (!Array.isArray(values)) values = [values];
      values.forEach((value: string) => {
        u.searchParams.append(key, value);
      });
    });
    url = u.toString();
  }
  return url;
};

const getWindow = () => {
  try {
    return window;
  } catch {
    return undefined;
  }
};

export default urlQuery;
