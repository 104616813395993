import { SnapshotCallback } from '@protos/snapshots';
import { streamSnapshots } from '@services/context';
import { useEffect } from 'react';

export const useSnapshotsStreaming = (callback: SnapshotCallback) => {
  useEffect(() => {
    streamSnapshots.subscribe(callback);

    return () => {
      streamSnapshots.unsubscribe();
    };
  }, [callback]);
};
