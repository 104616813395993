import { Box, CircularProgress, MenuItem, Select, Stack, Typography, styled } from '@mui/material';
import { ProductTenor } from '@protos/product';
import { streamDashboard } from '@services/context';
import { NumberInput } from '@shared/components/NumberInput';
import { useTradingTenorContext } from '@shared/contexts/TradingTenorProvider';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TradingButtons } from './TradingButtons';
import { TradingPanel, useTradingContext } from './TradingContext';
import { DEFAULT_TRADE_SIZE, MIN_SIZE_VALUE, TradingProducts } from './constants';

const TradingSelect = styled(Select)(({ theme }) => ({
  fontSize: 11,
  height: 20,
  width: '100%',
  borderRadius: 0,
  '& .MuiSelect-select': {
    padding: 0,
    paddingLeft: 4,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
    padding: 0,
  },
}));

const CustomTradingMenuProps = { PaperProps: { sx: { maxHeight: 100, width: 70, borderRadius: 0 } } };
const CustomTradingMenuItemProps = { fontSize: 11, padding: 0, paddingLeft: 1 };

interface TradingPanelProps {
  id: string;
  payload: TradingPanel;
}

export const TradingPanelComponent = ({ id, payload }: TradingPanelProps) => {
  const sizeInputRef = useRef<HTMLDivElement>(null);
  const { editWidget } = streamDashboard;
  const user = useUserContext();
  const { tenors } = useUserProductsAndTenorsContext();
  const monthlyTenors = useMemo(() => tenors.filter(tenor => tenor.frequency === 'monthly').slice(2), [tenors]);
  const { subscriptionSymbol, setSubscriptionSymbol, setPrices } = useTradingContext();
  const { getAllUsedTenors, setWidgetTenorMap } = useTradingTenorContext();

  //Set up trading form for the widget
  const {
    selectedProduct: incomingSelectedProduct,
    selectedTenor: incomingSelectedTenor,
    tradeSize: incomingTradeSize,
    selectedTradingAccount: incomingSelectedTradingAccount,
  } = payload;

  const [tradingForm, setTradingForm] = useState<TradingPanel>({
    selectedProduct: incomingSelectedProduct || TradingProducts[0],
    selectedTenor:
      incomingSelectedTenor ||
      monthlyTenors.filter(tenor => !(getAllUsedTenors(id, false) as ProductTenor[]).find(t => t.code === tenor.code))[0].code,
    tradeSize: incomingTradeSize || DEFAULT_TRADE_SIZE,
    selectedTradingAccount: incomingSelectedTradingAccount,
  });

  const [validTenors, setValidTenors] = useState<ProductTenor[]>([]);
  const [validBackTenors, setValidBackTenors] = useState<ProductTenor[]>([]);

  //Set up trade limits for the user and check if the trade size exceeds the limit
  const [userTradeLimits, setUserTradeLimits] = useState<Record<string, number>>({});
  const [isTradeLimitBreached, setIsTradeLimitBreached] = useState<boolean>(false);
  const showUserInputError = useMemo(
    () => isTradeLimitBreached || tradingForm.tradeSize <= 0 || tradingForm.tradeSize === undefined,
    [tradingForm, isTradeLimitBreached]
  );

  const [isLoadingPanel, setIsLoadingPanel] = useState<boolean>(true);
  const productTradingLimit = useMemo(() => userTradeLimits[tradingForm.selectedProduct.toLocaleLowerCase()], [userTradeLimits, tradingForm]);

  //Form Functions
  const onTradingProductChange = useCallback(
    (value: string) => {
      const { selectedProduct, selectedTenor } = tradingForm;

      if (TradingProducts.includes(value) && value !== selectedProduct) {
        const isNewProductSPR = value.toLocaleLowerCase().includes('spr');
        const isCurrentTenorObject = typeof selectedTenor === 'object';
        const usedTenors = getAllUsedTenors(id, isNewProductSPR);

        let newTenor: string | { left: string; right: string } = selectedTenor;

        if (isNewProductSPR && !isCurrentTenorObject) {
          const leftTenor = monthlyTenors[0].code;
          const usedSpreadProductTenors = (usedTenors as { left: ProductTenor; right: ProductTenor }[]).filter(t => t.left.code === leftTenor);
          let rightCode = monthlyTenors[1].code;

          setValidTenors(monthlyTenors);
          if (usedSpreadProductTenors.length) {
            const usedRightTenors = usedSpreadProductTenors.map(t => t.right.code);
            const availableRightTenors = monthlyTenors.filter(tenor => !usedRightTenors.includes(tenor.code) && tenor.code !== leftTenor);
            rightCode = availableRightTenors[0].code;
            setValidBackTenors(availableRightTenors);
          }

          newTenor = { left: leftTenor, right: rightCode };
        } else if (!isNewProductSPR && isCurrentTenorObject) {
          const availableNonSprTenors = monthlyTenors.filter(tenor => !(usedTenors as ProductTenor[]).find(t => t.code === tenor.code));
          newTenor = availableNonSprTenors[0].code;
          setValidTenors(availableNonSprTenors);
        }

        setTradingForm((prev: TradingPanel) => ({ ...prev, selectedProduct: value, selectedTenor: newTenor }));
      }
    },
    [tradingForm, getAllUsedTenors, monthlyTenors]
  );

  const onTenorChange = useCallback(
    (value: string, key?: 'left' | 'right') => {
      const { selectedTenor } = tradingForm;

      if (key) {
        if (selectedTenor[key] === value) return;
        else if (selectedTenor && typeof selectedTenor === 'object') {
          if (key === 'left') {
            const usedTenors = getAllUsedTenors(id, true);
            const usedSpreadProductTenors = (usedTenors as { left: ProductTenor; right: ProductTenor }[]).filter(t => t.left.code === value);

            if (usedSpreadProductTenors.length) {
              const usedRightTenors = usedSpreadProductTenors.map(t => t.right.code);
              const availableRightTenors = validTenors.filter(tenor => !usedRightTenors.includes(tenor.code) && tenor.code !== value);
              const newRightTenor = availableRightTenors[0].code;

              const newTenor = { left: value, right: newRightTenor };
              setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: newTenor }));
            } else {
              const successorTenorIndex = validTenors.findIndex(tenor => tenor.code === value) + 1;
              const newTenor = { left: value, right: validTenors[successorTenorIndex].code };
              setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: newTenor }));
            }
          } else {
            const newTenor = { left: selectedTenor.left, right: value };
            setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: newTenor }));
          }
        }
      } else if (selectedTenor !== value) {
        setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: value }));
      }
    },
    [tradingForm, getAllUsedTenors, validTenors]
  );

  const onTradeSizeChange = useCallback(
    (_, newValue) => {
      setTradingForm((prev: TradingPanel) => ({ ...prev, tradeSize: newValue }));

      if (newValue > productTradingLimit) {
        setIsTradeLimitBreached(true);
      } else if (isTradeLimitBreached) {
        setIsTradeLimitBreached(false);
      }
    },
    [isTradeLimitBreached, productTradingLimit]
  );

  //Set up trading limits from user context
  useEffect(() => {
    if (user && user.trade_limits.length) {
      const tradeLimits = user.trade_limits;
      setUserTradeLimits(tradeLimits.reduce((acc, limit) => ({ ...acc, [limit.symbol.toLocaleLowerCase()]: limit.limit }), {}));
    }
  }, [user]);

  //Highlight Size input field on widget load
  useEffect(() => {
    if (sizeInputRef.current) {
      sizeInputRef.current?.focus();
    }
  }, [sizeInputRef.current]);

  //Save widget based on user input in trading form
  useEffect(() => {
    const { selectedProduct, selectedTenor, tradeSize } = tradingForm;
    if (!selectedProduct || !selectedTenor || !tradeSize) return;

    const rfqSubscriptionSymbol =
      typeof selectedTenor === 'string'
        ? `${selectedProduct}${selectedTenor}-${tradeSize}`
        : `${selectedProduct.split('spr')[0]}${selectedTenor.left}-${selectedProduct.split('spr')[0]}${selectedTenor.right}-${tradeSize}`;

    const isSpread = typeof selectedTenor === 'object';
    const tenorsUsed = isSpread
      ? [
          {
            left: monthlyTenors.find(tenor => tenor.code === selectedTenor.left),
            right: monthlyTenors.find(tenor => tenor.code === selectedTenor.right),
          },
        ]
      : [monthlyTenors.find(tenor => tenor.code === selectedTenor)];
    const nonUndefinedTenorsUsed = tenorsUsed.filter(tenor => tenor !== undefined) as ProductTenor[];

    if (isSpread && (!validTenors.length || !validBackTenors.length)) {
      setValidTenors(monthlyTenors);

      const selectedTenorIndex = monthlyTenors.findIndex(tenor => tenor.code === selectedTenor.left);
      setValidBackTenors([...monthlyTenors].slice(selectedTenorIndex + 1));
    } else if (!isSpread && !validTenors.length) {
      const usedTenors = getAllUsedTenors(id, false);
      const availableNonSprTenors = [...monthlyTenors].filter(tenor => !(usedTenors as ProductTenor[]).find(t => t.code === tenor.code));

      setValidTenors(availableNonSprTenors);
    }
    setWidgetTenorMap(prev => ({ ...prev, [id]: { isSpread, tenorsUsed: nonUndefinedTenorsUsed } }));
    setPrices({ bid: undefined, ask: undefined });
    setSubscriptionSymbol(rfqSubscriptionSymbol);

    editWidget(id, {
      payload: { selectedProduct, selectedTenor, tradeSize, selectedTradingAccount: incomingSelectedTradingAccount },
    });

    if (isLoadingPanel) setIsLoadingPanel(false);
  }, [id, tradingForm, isLoadingPanel, monthlyTenors, validTenors, validBackTenors, incomingSelectedTradingAccount]);

  if (isLoadingPanel)
    return (
      <Stack direction="column" flex={1} alignItems="center" justifyContent="center">
        <Typography fontSize={11} color="textSecondary" tabIndex={-1}>
          Loading...
        </Typography>
      </Stack>
    );

  return (
    <Stack display="flex" flexDirection="column" flex={1} alignItems="center" padding={1} justifyContent="space-between">
      <Stack display="flex" gap={1} width="100%">
        <TradingSelect
          size="small"
          value={tradingForm.selectedProduct}
          onChange={(event: any) => onTradingProductChange(event.target.value)}
          MenuProps={CustomTradingMenuProps}
          tabIndex={-1}
        >
          {TradingProducts.map(option => (
            <MenuItem key={option} value={option} sx={CustomTradingMenuItemProps}>
              {option.toLocaleUpperCase()}
            </MenuItem>
          ))}
        </TradingSelect>
        {tradingForm.selectedTenor && typeof tradingForm.selectedTenor === 'object' ? (
          <Box display="flex" width="100%">
            <TradingSelect
              size="small"
              value={tradingForm.selectedTenor.left}
              onChange={(event: any) => onTenorChange(event.target.value, 'left')}
              MenuProps={CustomTradingMenuProps}
              tabIndex={-1}
            >
              {validTenors.map(option => (
                <MenuItem key={option.code} value={option.code} sx={CustomTradingMenuItemProps}>
                  {option.display.toLocaleUpperCase()}
                </MenuItem>
              ))}
            </TradingSelect>
            <TradingSelect
              size="small"
              value={tradingForm.selectedTenor.right}
              onChange={(event: any) => onTenorChange(event.target.value, 'right')}
              MenuProps={CustomTradingMenuProps}
              tabIndex={-1}
            >
              {validBackTenors.map(option => (
                <MenuItem key={option.code} value={option.code} sx={CustomTradingMenuItemProps}>
                  {option.display.toLocaleUpperCase()}
                </MenuItem>
              ))}
            </TradingSelect>
          </Box>
        ) : (
          <TradingSelect
            size="small"
            value={tradingForm.selectedTenor}
            onChange={(event: any) => onTenorChange(event.target.value)}
            MenuProps={CustomTradingMenuProps}
            tabIndex={-1}
          >
            {validTenors.map(option => (
              <MenuItem key={option.code} value={option.code} sx={CustomTradingMenuItemProps}>
                {option.display.toLocaleUpperCase()}
              </MenuItem>
            ))}
          </TradingSelect>
        )}
      </Stack>
      <Stack alignItems="center">
        <NumberInput
          ref={sizeInputRef}
          label="RFQ Lots"
          value={tradingForm.tradeSize}
          min={MIN_SIZE_VALUE}
          onChange={onTradeSizeChange}
          tabIndex={1}
          error={showUserInputError}
          allowClearable
          sizeSmall
          sx={{ width: '100%' }}
        />
        {isTradeLimitBreached && (
          <Typography fontSize={11} color="error" tabIndex={-1}>
            Trade size exceeds trading limit
          </Typography>
        )}
      </Stack>
      {subscriptionSymbol ? (
        <TradingButtons rfqSubscriptionSymbol={subscriptionSymbol} isDisabled={showUserInputError} tradingAccount={incomingSelectedTradingAccount} />
      ) : (
        <CircularProgress size={10} />
      )}
    </Stack>
  );
};
