import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Checkbox, Stack, TextField, Typography } from '@mui/material';
import { IconComponent } from '@shared/components/IconComponent';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { Product } from '@shared/protos/product';
import theme from '@shared/themes/darkTheme';
import { useCallback, useState } from 'react';
import { useSettlementPricesSettings } from '../../settlement-prices/SettlementPricesContext';
import { MAXIMUM_PRODUCTS_LIMIT, productFilterOptions } from '../utils';

const icon = <IconComponent name="ri-checkbox-blank-line" />;
const checkedIcon = <IconComponent name="ri-checkbox-line" />;

export const SettlementsProductsSettingsForm = () => {
  const { userProducts, loaded } = useUserProductsAndTenorsContext();
  const allProducts = userProducts.filter(
    product => !product.underlying_symbol || (product.calendar_type === 'spread' && product.tenor_frequency !== 'quarterly')
  );
  const { addColumn, selectedColIds, toggleColumn, selectedUserProducts } = useSettlementPricesSettings();
  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

  const [searchInput, setSearchInput] = useState('');

  const onChangeValues = useCallback(
    (selectedValues: Product[] | null) => {
      const deltaValues = selectedColIds.filter(column => !selectedValues?.find(elem => elem.symbol === column));
      if (deltaValues.length) {
        deltaValues.forEach(removedValue => {
          toggleColumn(removedValue);
        });
      }

      if (selectedValues?.length && addColumn) {
        selectedValues.forEach(selectedValue => {
          addColumn(selectedValue.symbol);
        });
      }
    },
    [toggleColumn, addColumn, selectedColIds]
  );

  return loaded && allProducts.length ? (
    <Stack direction="column" spacing={2}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox size="small" icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            <Typography fontSize={11}>{option.full_name}</Typography>
            <Typography variant="caption" marginLeft="auto" fontSize={11}>
              {option.symbol.toLocaleUpperCase()}
            </Typography>
          </li>
        )}
        inputValue={searchInput}
        onInputChange={(event, newInputValue, reason) => {
          if (reason !== 'reset') {
            setSearchInput(newInputValue);
          }
        }}
        defaultValue={allProducts.filter(product => selectedColIds.includes(product.symbol))}
        id="grouped-products"
        onChange={(_, newValues: Product[] | null) => onChangeValues(newValues)}
        size="small"
        options={allProducts.sort((a, b) => a.product_group.localeCompare(b.product_group))}
        groupBy={option => option.product_group}
        getOptionLabel={option => option.full_name}
        getOptionDisabled={() => selectedUserProducts.length >= MAXIMUM_PRODUCTS_LIMIT}
        renderInput={params => <TextField {...params} label="Add / Remove a product" />}
        renderGroup={params => (
          <Accordion {...params} disableGutters expanded={!!searchInput || expandedGroups.includes(params.group)}>
            <AccordionSummary
              sx={{ backgroundColor: theme.palette.background.default }}
              expandIcon={<IconComponent name="ri-arrow-drop-down-line" iconStyle={{ fontSize: 22 }} />}
              onClick={() => {
                if (expandedGroups.includes(params.group)) {
                  const idx = expandedGroups.findIndex(elem => elem === params.group);
                  const newList = [...expandedGroups];
                  newList.splice(idx, 1);
                  setExpandedGroups(newList);
                } else {
                  setExpandedGroups([...expandedGroups, params.group]);
                }
              }}
            >
              <Typography fontSize={11}>{params.group}</Typography>
            </AccordionSummary>
            <AccordionDetails>{params.children}</AccordionDetails>
          </Accordion>
        )}
        filterOptions={productFilterOptions}
        isOptionEqualToValue={(option, value) => option.symbol === value.symbol}
      />
      {selectedUserProducts.length >= MAXIMUM_PRODUCTS_LIMIT && (
        <Typography textAlign="left" variant="body" sx={{ color: 'error.main' }}>
          You've reached maximum number of {MAXIMUM_PRODUCTS_LIMIT} products that can been selected, please remove a few.
        </Typography>
      )}
    </Stack>
  ) : null;
};
