import { useURLSearchParams } from '@hooks/useURLSearchParams';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconComponent } from '@shared/components/IconComponent';
import { Filter, FilterType } from '@shared/protos/filter';
import { toLocaleISOString } from '@utils/date';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateInput } from './DateInput';
import { DateTime } from './DateTime';
import { DateTimeRange } from './DateTimeRange';
import { Search } from './Search';
import { Select } from './Select';

interface FilterPanelProps {
  filters?: Filter[];
  onSearch: (text: string) => void;
  disableSearch?: boolean;
  children?: React.ReactNode;
  title?: string;
  onRefreshClicked?: () => void;
}

const FilterContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
}));

const RenderFilter = (filter: Filter, filterParams: Record<string, any>, onFilterChange: (newFilter: Record<string, any>) => void) => {
  const { type, name, label, options = [] } = filter;
  const sortedOptions = [...options].sort((a, b) => a.localeCompare(b));

  switch (type) {
    case FilterType.AUTOCOMPLETE:
      return (
        <Autocomplete
          key={`blotter-filter-autocomplete-${label}`}
          disablePortal
          onChange={(_, newValue: string | null) => {
            onFilterChange({ [name]: newValue });
          }}
          id={`blotter-filter-autocomplete-${label}`}
          options={sortedOptions}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              size="small"
              label={label}
              sx={{
                minWidth: 170,
                '& .MuiInputBase-input': {
                  fontSize: 11,
                },
              }}
              InputLabelProps={{ style: { fontSize: 11 } }}
            />
          )}
          popupIcon={<IconComponent name="ri-arrow-down-s-line" />}
          value={filterParams[name]}
          ListboxProps={{ style: { fontSize: 11 } }}
          isOptionEqualToValue={(option, value) => option === value || value === ''}
        />
      );
    case FilterType.TEXTFIELD:
      return (
        <TextField
          id="outlined-basic"
          key={`blotter-filter-textfield-${label}`}
          label={label}
          variant="outlined"
          size="small"
          onChange={e => {
            const newValue = e.target.value;
            onFilterChange({ [name]: newValue });
          }}
          value={filterParams[name]}
          sx={{
            minWidth: 250,
            '& .MuiInputBase-input': {
              fontSize: 11,
            },
          }}
          InputLabelProps={{ style: { fontSize: 11 } }}
        />
      );
    case FilterType.DATETIME:
      return (
        <DateTime
          key={`blotter-filter-datetime-${label}`}
          label={label}
          value={filterParams[name]}
          onChange={newVal => onFilterChange({ [name]: newVal })}
        />
      );
    case FilterType.DATETIMERANGE:
      return (
        <DateTimeRange
          key={`blotter-filter-datetime-range-${label}`}
          value={{
            start: filterParams.start ? new Date(filterParams.start) : undefined,
            end: filterParams.end ? new Date(filterParams.end) : undefined,
          }}
          onChange={({ start, end }) => onFilterChange({ start, end })}
        />
      );
    case FilterType.DATE:
      return (
        <DateInput
          key={`blotter-filter-date-${label}`}
          value={filterParams[name]}
          onChange={newDate => onFilterChange({ [name]: newDate })}
          label={label}
        />
      );
    case FilterType.SELECT:
      return (
        <Select
          key={`blotter-filter-select-${label}`}
          options={sortedOptions}
          label={label}
          value={filterParams[name]}
          onChange={newVal => onFilterChange({ [name]: newVal })}
        />
      );
    case FilterType.SWITCH:
      return (
        <Stack direction="row" alignItems="center" key={`blotter-filter-toggle-${label}`}>
          <Switch
            checked={filterParams[name] === 'true'}
            size="small"
            onChange={(_, checked) => onFilterChange({ [name]: checked ? 'true' : 'false' })}
          />
          <Typography fontSize={11}>{label}</Typography>
        </Stack>
      );
    default:
      return null;
  }
};

export const FilterPanel = ({ filters, onSearch, disableSearch, title, onRefreshClicked, children }: FilterPanelProps) => {
  const { urlParams, resetURLSearchParams } = useURLSearchParams();

  const [filterParams, setFilterParams] = useState<Record<string, any>>(urlParams);
  const searchValue = useMemo(() => filterParams.search || '', [filterParams.search]);

  const onFilterChange = useCallback((newFilter: Record<string, any>) => {
    setFilterParams(prev => ({ ...prev, ...newFilter }));
  }, []);

  // Set FilterParams, Either use the ones in URL Params or the default ones, if they exist
  useEffect(() => {
    const defaultFilterParams = filters?.reduce((acc, filter) => {
      const { name, defaultValue } = filter;
      if (defaultValue && typeof defaultValue !== 'object') acc[name] = defaultValue;
      else if (defaultValue && typeof defaultValue === 'object') {
        const { start, end } = defaultValue;
        acc['start'] = toLocaleISOString(start);
        if (end) acc['end'] = toLocaleISOString(end);
      }

      return acc;
    }, {});

    // Validate URL Params
    const { search, ...urlFilters } = urlParams;

    const isValidURLParams = filters?.every(filter => {
      if ([FilterType.AUTOCOMPLETE, FilterType.SELECT].includes(filter.type)) {
        const filterValue = urlFilters[filter.name];
        if (filterValue && !filter.options?.includes(filterValue)) {
          return false;
        }
        return true;
      }
      return true;
    });

    if (!isValidURLParams) {
      resetURLSearchParams(defaultFilterParams || {});
      return;
    }

    const newFilterParams = { ...defaultFilterParams, ...urlParams };

    // Only update filterParams if they are different from the current state
    if (JSON.stringify(filterParams) !== JSON.stringify(newFilterParams)) {
      setFilterParams(newFilterParams);
    }
  }, []);

  // Make sure FilterParams and URLParams are in sync at all times
  useEffect(() => {
    resetURLSearchParams(filterParams);
  }, [filterParams]);

  return (
    <FilterContainer square>
      <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px', marginBottom: '-3px' }}>
        <Stack direction="row" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
          {title && <Typography variant="h4">{title}</Typography>}
          {title && <Divider orientation="vertical" style={{ marginLeft: 5 }} />}
          {filters?.map(filter => RenderFilter(filter, filterParams, onFilterChange))}
          {children}
          {onRefreshClicked && (
            <>
              <Divider orientation="vertical" style={{ marginLeft: 5 }} />
              <Tooltip title="Refresh">
                <IconButton onClick={onRefreshClicked} size="small" sx={{ padding: 0 }}>
                  <IconComponent name="ri-refresh-line" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Stack>
        {!disableSearch && <Search onSearch={onSearch} value={searchValue} />}
      </Box>
    </FilterContainer>
  );
};
