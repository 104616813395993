import { Fade, Stack, Typography } from '@mui/material';
import theme from '@shared/themes/darkTheme';
import { Component } from 'react';

export const unhandledErrorMessage =
  'An unexpected error has occurred. We apologise for the inconvenience and are working on a fix. Please refresh the page and try again.';

export default class AppErrorBoundary extends Component<{ children: any }, { hasError: boolean }> {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error boundary caught application error: ', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Fade in>
          <Stack display="flex" flexDirection="column" flex={1} justifyContent="center" minHeight="100vh">
            <Typography variant="h3" color={theme.palette.text.secondary} textAlign="center">
              ERROR - OOPS, SOMETHING WENT WRONG
            </Typography>
            <Typography variant="h6" color={theme.palette.text.secondary} textAlign="center">
              {unhandledErrorMessage}
            </Typography>
          </Stack>
        </Fade>
      );
    }

    return this.props.children;
  }
}
